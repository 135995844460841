import { getRequest, postRequest, patchRequest, deleteRequest } from './base_requests';

export function deleteSubstrateRequest(substrateID) {
  return deleteRequest({ url: `/admin/substrates/${substrateID}.json` })
}

export function updateSubstrateRequest(substrateID, params) {
  return patchRequest({url: `/admin/substrates/${substrateID}.json`, body: { substrate: params } })
}

export function splitSubstrateRequest(substrateID, splitFactor) {
  return postRequest({url: `/api/substrates/${substrateID}/split.json`, body: { split_factor: splitFactor }})
}

export function searchForSubstratesRequest(params) {
  return getRequest({url: `/api/substrates.json`, params: params })
}

export function bindSubstrateRequest(growthRunId, substrateIDs) {
  return patchRequest({url: `/api/growth_runs/${growthRunId}.json`, body: { growth_run: { substrate_ids: substrateIDs } } })
}
