import React from 'react';
import TextInput from './TextInput';
import { Dropdown } from 'react-bootstrap';
import ValidationErrors from './ValidationErrors';
import TranslateEnum from 'services/enums'


export default function TextInputWithUnits(props) {
  const { wrapperClassName='', errors=[], label=undefined, unitValue, availableUnits=[], handleUnitUpdate , additionalClassName} = props;
  const hasErrors = errors.length > 0;

  return (
    <React.Fragment>
      <div className="input-group">
        <TextInput {...props} handleInputChange={(e) => props.handleInputChange(props.name, e.target.value)}/>
        <ValidationErrors errors={errors} />

        <Dropdown className={`input-group-append ${additionalClassName}`}>
          <Dropdown.Toggle variant="outline-secondary">
            { TranslateEnum(availableUnits, unitValue) }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            { availableUnits.map((unit) => <Dropdown.Item key={`${props.name}-${unit[1]}`} active={unit[1] === unitValue} onClick={() => handleUnitUpdate(`${props.name}_unit`, unit[1])}>{unit[0]}</Dropdown.Item>) }
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </React.Fragment>
  );
}
