import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getNonce } from 'scripts/nonce_helper';

import { moveDocument, toggleDocument } from 'actions/CustomerReportBuilderPage';

import DraggableDocument from './DraggableDocument';
import NonDraggableDocuments from './NonDraggableDocuments';

const DocumentsForGrowthRun = ({ growth_run, draggable_documents, non_draggable_documents, handleMoveDocument, handleDocumentToggle }) => {
  const handleDocumentReorder = (result, runId) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }
    handleMoveDocument(runId, result.source.index, result.destination.index);
  }

  return (
    <div className={`growth_run_${growth_run.id}_documents`}>
      <h4 className="mt-4 mb-2 h5">{ growth_run.run_number || growth_run.reference_number_string } Documents</h4>
      {(draggable_documents.length + non_draggable_documents.length) === 0 && <span className='text-muted'>There are no documents associated with this run</span>}
      <DragDropContext onDragEnd={(result) => handleDocumentReorder(result, growth_run.id)} nonce={getNonce()}>
        <Droppable droppableId='layer-list' >
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="documentsArea">
              {draggable_documents.map((document, index) =>
                <DraggableDocument key={`run_${growth_run.id}_document_${document.id}`} document={document} handleDocumentToggle={() => handleDocumentToggle(growth_run.id, index, 'draggable')} index={index} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <NonDraggableDocuments handleDocumentToggle={(index) => handleDocumentToggle(growth_run.id, index, 'non_draggable')} documents={non_draggable_documents} />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    draggable_documents: state.report.documents[ownProps.growth_run.id].draggable,
    non_draggable_documents: state.report.documents[ownProps.growth_run.id].non_draggable
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleMoveDocument: moveDocument,
    handleDocumentToggle: toggleDocument
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsForGrowthRun);