export const toggleSelectedLayer = (layerId) => ({
  type: 'TOGGLE_SELECTED_LAYER',
  layerId
});

export const changeSelectedLayers = () => ({
  type: 'CHANGE_SELECTED_LAYERS',
});

export const confirmSelectedLayers = (selectedLayers) => ({
  type: 'CONFIRM_SELECTED_LAYERS',
  selectedLayers
})

export const toggleBulkEditingLayer = (layerId) => ({
  type: 'TOGGLE_BULK_EDITING_LAYER',
  layerId
});
