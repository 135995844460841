import 'styles/substrate_management';

import React from 'react';

import Button from '../../forms/Button';
import SplitDropdown from '../../forms/SplitDropdown';

import TextField from '../../forms/TextField'

import { updateSubstrateRequest } from '../../../services/api/substrate_requests';

class Substrate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      seriaNumber:   props.serial_number,
      errors:        [],
      isEditing:     false,
      splitMenuOpen: false
    }
  }

  handleSplit = (splitFactor) => {
    this.props.handleSplit(this.props.id, splitFactor);
  }

  handleSplitDropdownToggle = (isOpen) => {
    this.setState({
      splitMenuOpen: isOpen
    })
  }

  splitAction = () => {
    if (this.props.splitable) {
      return (
        <SplitDropdown id={`split_substrate_button_${this.props.id}`} handleSplit={this.handleSplit} handleDropdownToggle={this.handleSplitDropdownToggle} />
      )
    }
  }

  editAction = () => {
    if (this.props.splitable) {
      return (
        <Button className="btn-outline-secondary btn-sm" id={`edit_substrate_button_${this.props.id}`} icon="edit" onClickHandler={this.startEdit} />
      )
    }
  }

  removeAction = () => {
    if (this.props.removable) {
      return (
        <Button className="btn-outline-danger btn-sm substrate-remove-button" id={`remove_substrate_button_${this.props.id}`} icon="trash" onClickHandler={this.props.handleRemove} />
      )
    }
  }

  startEdit = () => {
    this.setState({
      isEditing: true
    })
  }

  cancelEdit = () => {
    this.setState({
      isEditing: false
    })
  }

  handleEdit = (e) => {
    this.setState({
      seriaNumber: e.target.value
    })
  }

  confirmEdit = () => {
    const editParams = { serial_number: this.state.seriaNumber };

    updateSubstrateRequest(this.props.id, editParams).then(async (res) => {
      if (res.status == 422) {
        let resData = await res.json();

        this.setState({
          errors: resData.errors.serial_number
        })
      } else {
        this.cancelEdit()
      }
    }).catch(errs => console.error(errs));
  }

  render() {
    let splitMenuOpenClass = ''
    if (this.state.splitMenuOpen) {
      splitMenuOpenClass = 'menu-shown';
    }

    if (this.state.isEditing) {
      return (
        <div className='substrate-group' role='group' id={`substrate_${this.props.id}`}  >
          <TextField value={this.state.seriaNumber} name="serial_number" handleEnter={this.confirmEdit} handleEscape={this.cancelEdit} handleInputChange={this.handleEdit} errors={this.state.errors} autoFocus={true} />
          { this.state.errors.length > 0 && <div className='invalid-feedback'>{this.state.errors.join(', ')}</div> }
          <div className='btn-group' role='group'>
            <Button className="btn-primary btn-sm" id={`update_substrate_button_${this.props.id}`} icon="check" onClickHandler={this.confirmEdit} />
            <Button className="btn-outline-secondary btn-sm" id={`cancel_edit_substrate_button_${this.props.id}`} icon="times" onClickHandler={this.cancelEdit} />
          </div>
        </div>
      );
    } else {
      return (
        <div className={`substrate-group ${splitMenuOpenClass}`} role='group' id={`substrate_${this.props.id}`}>
          <div className='substrate-serial-number'>
            {this.state.seriaNumber}
          </div>
          <div className='btn-group substrate-actions' role='group'>
            {this.splitAction()}
            {this.editAction()}
            {this.removeAction()}
          </div>
        </div>
      );
    }
  }
}

export default Substrate;
