import React from 'react';
import Button from './Button';


const FromActions = (props) => {
  return (
    <div className='form-actions mt-5'>
      <a href={props.backUrl} className= 'btn btn-secondary'>
        <i className='fas fa-arrow-left fa-fw'></i>
        Back
      </a>

      <Button className="btn-primary float-right" icon="check" text="Save" onClickHandler={props.saveHandler} disabled={props.disabled} />
    </div>
  );
}

export default FromActions;
