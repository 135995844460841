import { saveError, saveSuccess } from 'actions/form.base.actions';
import { createLayer, updateLayer, createRunLayer } from 'services/api/layer_requests';
import { getSelectedElementsFromGroups } from 'services/layers';

export const saveLayerMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SAVE_FORM") {
    return next(action)
  }

  const layer = action.form;

  const payload = {
    ...layer.values,
    ...{ elements: getSelectedElementsFromGroups(layer.groups) }
  }

  delete payload.id;

  let request = undefined;

  if(layer.type === 'editing') {
    request = updateLayer(layer.values.id, payload)
  } else if(layer.type === 'creating') {
    if (action.details.parent_type == 'growth_run'){
      request = createRunLayer(action.details.growth_run_id, payload);
    } else {
      request = createLayer(action.details.growth_request_id, action.details.growth_structure_id, payload);
    }
  }

  request
    .then(res => res.json())
    .then(res => dispatch(saveSuccess(res)))
    .catch(err => dispatch(saveError(err)));
};
