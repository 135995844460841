import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveForm, cancelEdit } from 'actions/form.base.actions';

import { Modal } from 'react-bootstrap';

import Button from 'components/forms/Button';

import LayerDetails from 'components/pages/customers/growth_structures/layers/LayerDetails';
import MachineSettingFields from './MachineSettingFields';

const BulkEditMachineSettingForm = ({bulkEditingLayers, machineSettings, handleSaveForm, handleCancelEdit}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSaveForm();
  }

  return (
    <Modal show={true} onHide={handleCancelEdit} size='lg'>
      <form className="machine-settings-form" noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Machine Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-sm-8'><MachineSettingFields machineSettings={machineSettings}/></div>
            <div className='col-sm-4'>
              Layers selected:
              <ul>
                { bulkEditingLayers.map(layer => {
                  return (<li key={layer.id}><LayerDetails {...layer} /></li>)
                }) }
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            icon='times'
            text='Cancel edit'
            className='btn-secondary'
            onClickHandler={handleCancelEdit} />

          <Button
            icon='save'
            text='Save settings'
            className='btn-primary ml-auto'
            type='submit' />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    machineSettings: state.form.machineSettings,
    bulkEditingLayers: state.layers.filter((layer) => layer.selected)
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleSaveForm: saveForm,
    handleCancelEdit: cancelEdit
  }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(BulkEditMachineSettingForm)
