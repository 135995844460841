import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectStructure, toggleGrowthRun, toggleDocument, submitReport, moveGrowthRun } from 'actions/CustomerReportBuilderPage';

import GrowthRequestDetails from './GrowthRequestDetails';
import RunsList from './RunsList';
import DocumentsList from './DocumentsList';
import Button from 'components/forms/Button'

class ReportForm extends React.Component {
  handleGrowthRunReorder = (result) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }
    this.props.moveGrowthRun(result.source.index, result.destination.index);
  }

  render() {
    const {
      growth_runs, selectedGrowthStructure, selected_growth_runs, toggleGrowthRun, submitReport,
      form_submitting, form_error } = this.props;

    return (
      <div className="reportBuilder">
        <div className="row mb-4">
          <div className="col-lg-8">
            <RunsList runs={growth_runs} handleGrowthRunSelect={toggleGrowthRun} handleGrowthRunReorder={this.handleGrowthRunReorder} />
          </div>
          <div className="col-lg-4">
            <GrowthRequestDetails growth_structure={selectedGrowthStructure} />
          </div>
        </div>
        { selected_growth_runs.length > 0 &&
          <React.Fragment>
            <DocumentsList growth_runs={selected_growth_runs} />
            { form_error && <p className="text-danger">{ form_error }</p> }
            <Button className="btn btn-primary mt-3"
              iconVariant={`fas ${form_submitting ? 'fa-spin' : ''}`}
              onClickHandler={submitReport}
              icon={form_submitting ? 'circle-notch' : 'file-pdf'}
              text={form_submitting ? 'Generating...' : 'Generate report'}
              disabled={form_submitting} />
          </React.Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    growth_request: state.report.data,
    selectedStructure: state.report.selectedStructure,
    selectedGrowthStructure: state.report.selectedStructure,
    growth_runs: state.report.growth_runs,
    selected_growth_runs: state.report.growth_runs.filter(growth_run => growth_run.checked),
    documents: state.report.documents,
    form_submitting: state.report.form.submitting,
    form_error: state.report.form.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    selectStructure: selectStructure,
    toggleGrowthRun: toggleGrowthRun,
    toggleDocument: toggleDocument,
    submitReport: submitReport,
    moveGrowthRun: moveGrowthRun
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm)