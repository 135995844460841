import React from 'react';

const SectionError = ({ errors }) => {
  return (
    <div className="alert alert-danger">
      { errors.join(', ') }
    </div>
  );
}

export default SectionError;
