import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleProperty, setProperty } from 'actions/GrowthRunManagementPage';

const MachineSettingInput = ({machineSetting, handleToggleProperty, handleSetProperty, ...props}) => {
  const { name, visible, value } = machineSetting;

  return(
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id={`select-${name}`} checked={visible} onChange={() => handleToggleProperty(props.type, props.property)} />

          <label className="custom-control-label" htmlFor={`select-${name}`}><span className="sr-only">Select {name}</span></label>
        </div>
      </div>

      <div className="form-label-group">
        <input
          id={`value-${name}`}
          className="form-control"
          required={true}
          value={visible ? value : ''}
          disabled={!visible}
          onChange={(e) => handleSetProperty(props.type, props.property, e.target.value)}
          aria-required={true}
          type="text"
          placeholder={name}
        />
        <label htmlFor={`value-${name}`} className='form-control-label'>{name}</label>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    machineSetting: state.form.machineSettings[ownProps.type][ownProps.property.name]
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleToggleProperty: toggleProperty,
    handleSetProperty:    setProperty
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineSettingInput)
