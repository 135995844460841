import React from 'react';
import I18n from 'locales/index.js.erb'

const MachineLog = ({ machine_log: { id, notes, status, log_type, created_at, staff: { givenname, sn }, document_links= [] }, previous_machine_log='' }) => {
  return (
    <div className={`machineLog ${machine_log_status_class(status, previous_machine_log.status)} ${log_type} machine_log_${id}`}>
      <div className="machineLogTimeline">
        <i className={`fas fa-fw fa-${machine_log_status_icon(status, previous_machine_log.status)}`} title={status} />
      </div>
      <div className="machineLogBody">
        <div className="machineLogDetails">
          <div className="machineLogType">
            <i className={`far fa-fw fa-${machine_log_type_icon_mapping()[log_type]}`} />
            <span><strong>{ I18n.t(`enums.machine_log.log_type.${log_type}`) }</strong></span>
            <span>Machine status: { status }</span>
          </div>
          <div className="timeLogged">
            Logged by {givenname} {sn} on { format_log_date(created_at) }
          </div>
        </div>
        <p>{ notes }</p>
        <div className='logDocuments'>
          {document_links.map(doc => <a href={doc.url} key={doc.url} className='btn btn-outline-info btn-xs'>{doc.filename}</a> )}
        </div>
      </div>
    </div>
  )
}

const machine_log_type_icon_mapping = () => {
  return {
    maintenance: 'tools',
    issue_investigation: 'search',
    fix: 'wrench',
    note: 'sticky-note',
    status_update: 'exclamation-triangle'
  }
}

const machine_log_status_class = (currentStatus, previousStatus) => {
  if(currentStatus == 'down' && previousStatus == 'down') {
    return 'down'
  } else if(currentStatus == 'up' && previousStatus == 'down') {
    return 'nowUp'
  } else if(currentStatus == 'down' && previousStatus == 'up') {
    return 'nowDown'
  } else if(currentStatus == 'down') {
    return 'down'
  } else if(currentStatus == 'up') {
    return 'up'
  }
}

const machine_log_status_icon = (currentStatus, previousStatus) => {
  if(currentStatus == 'up' && previousStatus == 'down') {
    return 'chevron-circle-up'
  } else if(currentStatus == 'down' && previousStatus == 'up') {
    return 'chevron-circle-down'
  } else {
    return 'circle'
  }
}

const format_log_date = (date) => {
  const parsedDate = Date.parse(date);
  const dateObj = new Date(parsedDate);
  return `${pad_start(dateObj.getDate(), 2)}/${pad_start((dateObj.getMonth() + 1), 2)}/${dateObj.getFullYear()} at ${pad_start(dateObj.getHours(), 2)}:${pad_start(dateObj.getMinutes(), 2)}:${pad_start(dateObj.getSeconds(), 2)}`
}

function pad_start(original, length) {
  return original.toString().padStart(length, '0')
}

export default MachineLog;