import React from 'react';

export default function TextField({
  placeholder=undefined,
  label=undefined,
  value='',
  name,
  type="text",
  inputClassName='',
  required=false,
  handleInputChange,
  handleEnter=undefined,
  handleEscape=undefined,
  errors=[],
  autoFocus=false, 
  helpText=undefined
}) {
  const hasErrors = errors.length > 0;
  const onKeyDown = (e) => {
    if (handleEnter && e.which === 13) {
      handleEnter()
    } else if (handleEscape && e.which === 27) {
      handleEscape()
    }
  }

  return (
    <React.Fragment>
      <input name={name} id={name} className={`form-control ${inputClassName} ${hasErrors ? 'is-invalid' : ''}`} placeholder={label} required={required} aria-required={required} type={type} value={value} onKeyDown={onKeyDown} onChange={handleInputChange} autoFocus={autoFocus}/>
      { label && <label htmlFor={name} className='form-control-label'>{label}</label> }
      { helpText && <small id={name + 'Help'} className="form-text text-muted">{helpText}</small> }
    </React.Fragment>
  );
}
