import merge from 'deepmerge';

export const MachineSettings = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_MACHINE_SETTINGS': {
      let newState = { ...state };

      action.layerIds.forEach(layerId => {
        newState[layerId] = merge(newState[layerId], action.machineSettings)
      });

      return newState;
    }

    default: {
      return state;
    }
  }
}
