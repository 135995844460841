import { postRequest, patchRequest, deleteRequest } from './base_requests';

export function createCharacterisation(growth_request_id, growth_structure_id, characterisation) {
  return postRequest({ url: `/api/growth_requests/${growth_request_id}/growth_structures/${growth_structure_id}/characterisations`, body: { characterisation }})
}

export function updateCharacterisation(characterisationId, characterisation) {
  return patchRequest({ url: `/api/characterisations/${characterisationId}`, body: { characterisation } })
}

export function deleteCharacterisation(characterisationId) {
  return deleteRequest({ url: `/api/characterisations/${characterisationId}` })
}
