import React from 'react';
import { FormCheck } from 'react-bootstrap';

const DocumentContent = ({ document, handleDocumentToggle }) => {
  return (
    <div className='draggableContent'>
      <FormCheck
        custom
        type='checkbox'
        key={`document_${document.id}`}
        id={`document_${document.id}`}
        className="mr-4"
        label={document.filename}
        checked={document.checked || document.checked === undefined}
        onChange={handleDocumentToggle}
      />
    </div>
  )
}

export default DocumentContent;