export const toggleElement = (name, group, selected) =>({
  type: 'TOGGLE_ELEMENT',
  name,
  group,
  selected
});

export const setPercentage = (name, group, percentage) => ({
  type: 'SET_PERCENTAGE',
  name,
  group,
  percentage
});
