import React from 'react';
import 'lodash';

class ValidationError extends React.Component {
  wrapperClass() {
    if (_.isEmpty(this.props.wrapperClass)) {
      return 'invalid-feedback';
    } else {
      return this.props.wrapperClass;
    }
  }
  render() {
    if (_.isEmpty(this.props.errors) || _.isEmpty(this.props.errors[this.props.errorKey]) ) {
      return (<React.Fragment />);
    } else {
      return (
        <div className={this.wrapperClass()}>{this.props.errors[this.props.errorKey].join()}</div>
      );
    }
  }
}

export default ValidationError;
