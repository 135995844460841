import React from 'react';
import { Provider, connect } from 'react-redux';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';

import { Form, SubstrateReducer, SelectedSubstratesReducer } from 'reducers/SubstrateSelectionPage';
import { AlertsReducer } from 'reducers/Alerts';
import SubstrateSelectionScreen from './SubstrateSelectionScreen';

import { searchSubstrateMiddleware, bindSubstrateMiddleware, splitSubstrateMiddleware } from 'middleware/substrates';

class SubstrateSelectionPage extends React.Component {
  render() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
      combineReducers({ form: Form, substrates: SubstrateReducer, selectedSubstrates: SelectedSubstratesReducer, alerts: AlertsReducer }),
      { form: { values: this.props.default_filters, searching: false }},
      composeEnhancers(
        applyMiddleware(searchSubstrateMiddleware, bindSubstrateMiddleware, splitSubstrateMiddleware)
      ),
    );

    return (

      <Provider store={store}>
        <SubstrateSelectionScreen growth_run_id={this.props.growth_run_id} collections={this.props.collections} filters_provided={ false } />
      </Provider>
    );
  }
}

export default SubstrateSelectionPage;