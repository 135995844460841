import { searchForSubstratesRequest } from 'services/api/substrate_requests';
import { finishSearching, searchFiltersRequired } from 'actions/SubstrateSelectionPage';

const containsValues = (obj) =>{
  var hasValues = false

  for (const [key, value] of Object.entries(obj)) {
    if (value && key !== "search[serial_number_cont]") {
      hasValues = true
    }
  }

  return hasValues
}

const hasEnoughCharactersInSerial = (obj) =>{
  var hasEnoughCharacters = false

  if (obj["search[serial_number_cont]"] && obj["search[serial_number_cont]"].length > 2) {
    hasEnoughCharacters = true
  }

  return hasEnoughCharacters
}

export const searchSubstrateMiddleware = ({ dispatch, getState }) => next => action => {
  
  switch(action.type) {
    case "START_SEARCHING": {
      if (containsValues(action.params) || hasEnoughCharactersInSerial(action.params)) {
        searchForSubstratesRequest(action.params).
        then(res => res.json()).
        then(res => {
          dispatch(finishSearching(res))
        }).
        catch(err => console.log(err))
      } else {
        dispatch(searchFiltersRequired())
      }
    }
  }
  return next(action)
};
