export function moveDocument(state, runId, initialPosition, endPosition) {
  let newState = {...state};
  
  newState.documents[runId].draggable = [...newState.documents[runId].draggable]
  
  const [moved] = newState.documents[runId].draggable.splice(initialPosition, 1);
  newState.documents[runId].draggable.splice(endPosition, 0, moved);

  return newState;
}

export function moveGrowthRun(state, initialPosition, endPosition) {
  let newState = { ...state };

  const [moved] = newState.growth_runs.splice(initialPosition, 1);
  newState.growth_runs.splice(endPosition, 0, moved);

  return newState;
}