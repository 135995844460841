import React from 'react';

export default function ValidationErrors(props) {
  const { errors = [] } = props;

  if (errors.length > 0) {
    return(
      <div className='invalid-feedback'>{errors.join(', ')}</div>
    );
  } else {
    return null;
  }
}
