import { defaultFormValues, disableIncompatibleGroups } from './'

export function layerRepeats(repeats) {
  if(repeats > 1) {
    return ` × ${repeats}`
  } else {
    return ''
  }
}

export function sortElements(elements) {
  const alloyElementOrder = ['In', 'Al', 'Ga', 'Si', 'Ge', 'Sn', 'As', 'P', 'Sb', 'Bi', 'N'];

  return elements.sort((element1, element2) => {
    const orderOfElement1 = alloyElementOrder.indexOf(element1.name);
    const orderOfElement2 = alloyElementOrder.indexOf(element2.name);

    if (orderOfElement1 < orderOfElement2) {
      return -1;
    } else if (orderOfElement1 > orderOfElement2) {
      return 1;
    } else {
      return 0;
    }
  })
}

export function formatDopingLevel(dopingLevel) {
  if(dopingLevel === '') {
    return '';
  } else {
    return parseFloat(dopingLevel).toExponential().toString().replace(/\+/, '')
  }
}


export function thicknessInNm(layer) {
  const thickness = parseFloat(layer.thickness);
  switch (layer.thickness_unit) {
    case 'angstrom': {
      return thickness / 10;
    }
    case 'micrometer': {
      return thickness * 1000;
    }
    default: {
      return thickness;
    }
  }

}

export function moveLayer(layers, initialPosition, endPosition) {
  let newLayers = [...layers];
  const [moved] = newLayers.splice(initialPosition, 1);
  newLayers.splice(endPosition, 0, moved);
  return newLayers;
}

export function buildExistingLayerForForm(layer) {
  const selectedElements = layer.elements;
  delete layer.elements;

  let formState = defaultFormValues();

  formState.values = { ...formState.values,  ...layer };

  selectedElements.forEach(element => {
    formState.groups[element.group]['elements'][element.name] = { ...element }
  });

  formState['groups'] = disableIncompatibleGroups(formState['groups']);

  return formState;
}
