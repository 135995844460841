import React from 'react';
import MachineSettingInput from './MachineSettingInput';

const MachineSettingFields = ({machineSettings}) => {
  return (
    <React.Fragment>
      <div className='machine-property-group'>
        <label className='form-control-label'>Properties:</label>
        <div className='properties'>
          {Object.values(machineSettings['machineProperties']).map(setting => (
            <MachineSettingInput key={setting.name} type='machineProperties' property={setting} />
          )) }
        </div>
      </div>

      <div className='machine-property-group'>
        <label className='form-control-label'>Controls:</label>
        <div className='properties'>
          {Object.values(machineSettings['controls']).map(setting => (
            <MachineSettingInput key={setting.name} type='controls' property={setting} />
          )) }
        </div>
      </div>

      <div className='machine-property-group'>
        <label className='form-control-label'>Results:</label>
        <div className='properties'>
          {Object.values(machineSettings['results']).map(setting => (
            <MachineSettingInput key={setting.name} type='results' property={setting} />
          )) }
        </div>
      </div>
    </React.Fragment>
  )
}

export default MachineSettingFields;
