import React from 'react';

const Button = ({ className, onClickHandler, icon, text, id, disabled, type='button', iconVariant='fas', confirm=undefined }) => {
  return (
    <button className={`btn ${className}`} type={type} onClick={onClickHandler} id={id} disabled={disabled} data-confirm={confirm}>
      { icon && <i className={`${iconVariant} fa-${icon} fa-fw mr-1`}></i> }
      {text}
    </button>
  );
}

export default Button;
