export const updateFormValue = (name, value) => ({
  type: 'UPDATE_FORM_VALUE',
  name,
  value
});

export const saveForm = (form, details) => ({
  type: 'SAVE_FORM',
  form,
  details
});

export const saveSuccess = (savedObject) => ({
  type: 'SAVE_SUCCESS',
  savedObject
});

export const saveError = (errors) => ({
  type: 'SAVE_FAILURE',
  errors
});

export const cancelEdit = () => ({
  type: 'CANCEL_EDIT'
});
