import React from 'react';

import SentryErrorBoundary from 'components/Sentry/SentryErrorBoundary';
import MachineLog from './MachineLog';

const MachineLogs = ({ machine_logs }) => {
  return (
    <SentryErrorBoundary>
      <div className="machineLogs">
        { machine_logs.map((machine_log, index) => <MachineLog machine_log={machine_log} key={machine_log.id} previous_machine_log={machine_logs[index + 1]} />) }
      </div>
    </SentryErrorBoundary>
  )
}

export default MachineLogs;