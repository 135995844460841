import { moveLayer } from 'services/layers'

export const Layers = (state = [], action) => {
  switch (action.type) {
    case 'SAVE_SUCCESS': {
      let newState = [...state];
      const layer = action.savedObject;
      const layerPosition = state.map(layer => layer.id).indexOf(layer.id);

      if (layerPosition > -1) {
        newState[layerPosition] = layer
      } else {
        newState.unshift(layer)
      }

      return newState;
    }

    case 'MOVE_LAYER': {
      const { initialPosition, endPosition } = action;
      return moveLayer(state, initialPosition, endPosition);
    }
    case 'LAYER_MOVE_FAILURE': {
      const { initialPosition, endPosition } = action;
      return moveLayer(state, initialPosition, endPosition);
    }
    case 'LAYER_DELETE_SUCCESS': {
      return state.filter(layer => layer.id !== action.layerId );
    }
    default: {
      return state;
    }
  }
}
