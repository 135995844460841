import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import * as Middlewares from 'middleware/growth'

import { Layers, MachineSettings, Form } from 'reducers/GrowthRunManagementPage';

import LayerList from './LayerList';

class LayersManagementPage extends React.Component {
  render() {
    const store = createStore(combineReducers({
      layers: Layers,
      machineSettings: MachineSettings,
      form: Form,
      growthRun: (state = {}, action) => state
    }), {
      layers: this.props.layers,
      machineSettings: this.props.machine_settings,
      growthRun: { id: this.props.growth_run_id }
    },
    applyMiddleware(...Object.values(Middlewares))
  );

    return (
      <Provider store={store}>
        <LayerList details={this.props.details} />
      </Provider>
    );
  }
}

export default LayersManagementPage;
