import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { newCharacterisation } from 'actions/CharacterisationsPage';
import { updateFormValue, cancelEdit, saveForm } from 'actions/form.base.actions';

import TextArea from 'components/forms/TextArea';
import SelectInput from 'components/forms/SelectInput';
import Button from 'components/forms/Button';

class CharacterisationForm extends React.Component {
  handleSaveSubmit = (e) => {
    e.preventDefault();
    this.props.saveCharacterisation(this.props.form, this.props.details)
  }

  render() {
    const { form, values={}, groups=[], errors={}, handleFormUpdate, cancelEdit, newCharacterisation, collections } = this.props;

    return(
      <form className="characterisation-form form-bordered" noValidate onSubmit={this.handleSaveSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <SelectInput
              label='Method'
              required={true}
              name='method'
              value={values.characterisation_method}
              errors={errors['characterisation_method']}
              autoFocus={true}
              handleInputChange={(e) => handleFormUpdate('characterisation_method', e.target.value)}
              collection={collections['characterisation_method_collection']} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <TextArea
              label='Purpose'
              name='purpose'
              value={values.purpose}
              errors={errors['purpose']}
              handleInputChange={(e) => handleFormUpdate('purpose', e.target.value)} />
          </div>
        </div>
        <div className='form-actions d-flex mt-4'>
          <Button
            icon='times'
            text='Cancel edit'
            className='btn-secondary'
            onClickHandler={cancelEdit} />
          <Button
            icon='save'
            text='Save'
            className='btn-primary ml-auto'
            type='submit'
            disabled={form.saving} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form:   state.form,
    values: state.form.values,
    errors: state.form.errors
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate:     updateFormValue,
    saveCharacterisation: saveForm,
    cancelEdit:           cancelEdit,
    newCharacterisation:  newCharacterisation
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CharacterisationForm)
