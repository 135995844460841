import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Batch from './Batch';

import { newAlert } from 'actions/Alerts/alerts.actions'

import { addSubstrateToStockRequest } from '../../../services/api/stock_requests';
import { deleteSubstrateRequest, splitSubstrateRequest } from '../../../services/api/substrate_requests';

class SubstrateManagementForm extends React.Component {
  constructor(props) {
    super(props)
    let { batches } = this.props;
    let values = {}
    let errors = {}

    Object.keys(batches).forEach(batchID => {
      values[batchID] = {};
      values[batchID].nextSubstrateValue = `${batches[batchID].batch_number}-${batches[batchID].next_substrate_number_in_batch}`;
      errors[batchID] = {};
    });

    this.state = { batches, values, errors }
  }

  handleSubstrateSplit = (substrateID, splitFactor) => {
    splitSubstrateRequest(substrateID, splitFactor).then(async (res) => {
      let resData = await res.json();

      if (res.status == 422) {
        console.log(resData);
      } else {
        const { batchID, newSubstrates } = resData;
        let { batches } = this.state;
        let substrates = {};

        Object.keys(batches[batchID].substrates).forEach((substrate) => {
          if (substrate === `substrate-${substrateID}`) {
            newSubstrates.forEach((newSubstrate) => {
              substrates[`substrate-${newSubstrate.id}`] = newSubstrate;
            })
          } else {
            substrates[substrate] = batches[batchID].substrates[substrate]
          }
        })

        batches[batchID].substrates = substrates;

        this.props.newAlert('success', `Successfully split substrate into ${newSubstrates.map(substrate => substrate.serial_number).join(', ')}`)

        this.setState({ batches })
      }
    }).catch(errs => console.error(errs));
  }

  handleSubstrateRemove = (batchID, substrateID) => {
    deleteSubstrateRequest(substrateID).then(async (res) => {
      if (res.status == 422) {
        let errors = await res.json();
        console.log(errors);
      } else {
        let { batches } = this.state;
        delete batches[batchID].substrates[`substrate-${substrateID}`]
        this.setState({ batches })
      }
    }).catch(errs => console.error(errs));
  }

  handleSubstrateAdd = (stockID, batchID, batchNumber) => {
    addSubstrateToStockRequest(stockID, batchID, batchNumber).then(async (res) => {
      let resData = await res.json();

      if (res.status == 422) {
        let { errors } = this.state;
        errors[batchID] = resData;

        this.setState({ errors })
      } else {
        let { batches, values } = this.state;

        batches[batchID].substrates[resData.substrate.id] = { serial_number: resData.substrate.serial_number }
        values[batchID].nextSubstrateValue = `${batches[batchID].batch_number}-${resData.next_substrate_number_in_batch}`

        this.setState({ batches })
      }
    }).catch(errs => {
      console.error(errs);
    });
  }

  handleNextSubstrateInput = (e, batchID) => {
    let { values } = this.state;
    values[batchID].nextSubstrateValue = e.target.value;
    this.setState({ values })
  }

  render() {
    const { batches, values, errors } = this.state;

    return (
      <React.Fragment>
        {
          Object.keys(batches).map((batchID) =>
            <Batch batch={batches[batchID]} id={batchID} handleSubstrateAdd={this.handleSubstrateAdd} handleSubstrateSplit={this.handleSubstrateSplit} handleNextSubstrateInput={this.handleNextSubstrateInput} handleSubstrateRemove={this.handleSubstrateRemove} values={values[batchID]} errors={errors[batchID]} key={`batch_${batchID}`} />
          )
        }
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    newAlert
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(SubstrateManagementForm)