import { present, empty, notANumber, runValidations } from '../../base.validations';

const attributeValidators = {
  repeats: [present],
  thickness: [present],
  doping_type: [present]
}

const validateMaybeNumericFields = (layer) => {
  return ['doping_level', 'target_wavelength', 'wavelength_tolerance', 'thickness_tolerance', 'doping_tolerance', 'material_tolerance'].reduce((x, field) => {
    if(layer[`${field}_unit`] !== 'other') {
      const value = layer[field]
      x[field] = (notANumber(value) && !empty(value)) ? ["not a number - select 'Other' units if you need to add a comment"] : []
      return x
    }
    return x
  }, {})
}

export function layerValidations(layer) {
  let errors = runValidations(layer, attributeValidators);
  if(layer.doping_type !== 'undoped') {
    errors = { ...errors,  ...runValidations(layer, { doping_level: [present]}) }
  }
  return { ...errors,  ...validateMaybeNumericFields(layer) };
}
