import React from 'react';
import FormField from './FormField';

export default function TextArea({ label=undefined, value='', name, wrapperClassName='', inputClassName='', required=false, handleInputChange, errors=[], autoFocus=false, min=undefined, max=undefined, step=1 }) {
  const hasErrors = errors.length > 0;
  return (
    <FormField label={label} wrapperClassName={wrapperClassName} errors={errors}>
      <textarea name={name} id={name} className={`form-control ${inputClassName} ${hasErrors ? 'is-invalid' : ''}`} required={required} aria-required={required} type="number" value={value} min={min} max={max} step={step} onChange={handleInputChange} placeholder={label} autoFocus={autoFocus}/>
      { label && <label htmlFor={name} className='form-control-label'>{label}</label> }
    </FormField>
  );
}
