import React from 'react';
import ElementInput from 'components/forms/ElementInput';

import { totalPercentageForGroup, isGroupSelected } from 'services/layers';

const MaterialGroupFields = ({ group, errors=[], toggleElement, setPercentage }) =>  {
  const hasError = errors.length > 0;

  return(
    <div className={`elements-group ${hasError ? 'has-error' : '' }`}>
      <label className='form-control-label'>Group {group.name}{ isGroupSelected(group) && <small className='ml-2'>({ totalPercentageForGroup(group) }% in total)</small> }</label>

      <div className="elements">
        { Object.keys(group.elements).map((elementName) => {
          return (<ElementInput
            key={elementName}
            disabled={group.disabled}
            group={group.name}
            name={elementName}
            handleToggle={toggleElement}
            handleChange={setPercentage}
            {...group.elements[elementName]} />);
        })}
      </div>

      <div className="invalid-feedback">
        { errors.join([', ']) }
      </div>
    </div>
  )
}

export default MaterialGroupFields;
