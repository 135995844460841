import { present, runValidations } from '../../base.validations';

const attributeValidators = {
  purpose:                 [present],
  characterisation_method: [present]
}

export function characterisationValidations(characterisation) {
  return runValidations(characterisation, attributeValidators);
}
