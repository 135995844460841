import React from 'react';
import { FormCheck } from 'react-bootstrap';

const StructureList = ({ handleStructureSelect, structures, selectedStructure }) => {
  return(
    <React.Fragment>
      <h3>Select Structure for report</h3>
      <div className="structures d-flex flex-row mb-4">
        {
          structures.map(structure =>
            <FormCheck
              custom
              type='radio'
              key={`input_group_${structure.id}`}
              id={`structure_radio_${structure.id}`}
              className="mr-4"
              name="structure_radio"
              label={structure.reference_number}
              checked={selectedStructure === structure.id}
              onChange={() => handleStructureSelect(structure.id)}
            />
          )
        }
      </div>
    </React.Fragment>
  );
}

export default StructureList;