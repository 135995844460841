import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Alert from 'react-bootstrap/Alert'

import { dismissAlert } from 'actions/Alerts/alerts.actions'

const Alerts = ({ alerts, ...props }) => {
	return (
		<div className="alertsArea">
			{ alerts.map(alert =>
					<Alert variant={alert.variant} onClose={() => props.dismissAlert(alert.timestamp)} dismissible key={`alert_${alert.timestamp}`}>
						{
							alert.content
						}
					</Alert>
				)}
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		alerts: state.alerts
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		dismissAlert
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
