export const SubstrateReducer = (state = [], action) => {
  switch (action.type) {
    case 'FINISH_SEARCHING': {
      return action.substrates;
    }

    case 'SEARCH_FILTERS_REQUIRED': {
      return [];
    }

    case 'SPLIT_SUBSTRATE_SUCCESS': {
      let newState = [...state];

      newState.splice(action.oldSubstratePosition, 1);
      newState.splice(action.oldSubstratePosition, 0, ...action.newSubstrates);

      return newState;
    }

    default:
      return state;
  }
}
