import React from 'react';

import DocumentContent from './DocumentContent';

const NonDraggableDocuments = ({ documents, handleDocumentToggle }) => {
  return (
    <React.Fragment>
      {
        documents.map((document, index) => (
          <div key={`document_${document.id}`} className={`nonDraggableDocument document_${document.id} ${document.checked === false ? 'unselected' : ''}`}>
            <div className="nonDraggableSpacer">
              <i className='fas fa-file-pdf fa-fw' />
            </div>
            <DocumentContent document={document} handleDocumentToggle={() => handleDocumentToggle(index)} />
          </div>
        ))
      }
    </React.Fragment>  
  )
}

export default NonDraggableDocuments;