export const Characterisations = (state = [], action) => {
  switch (action.type) {
    case 'SAVE_SUCCESS': {
      let newState = [...state];
      const characterisation = action.savedObject;

      const position = state.map(characterisation => characterisation.id).indexOf(characterisation.id);

      if (position > -1) {
        newState[position] = characterisation
      } else {
        newState.unshift(characterisation)
      }

      return newState;
    }
    case 'CHARACTERISATION_DELETE_SUCCESS': {
      return state.filter(characterisation => characterisation.id !== action.characterisationId );
    }
    default: {
      return state;
    }
  }
}
