import { createMachine, updateMachine } from 'services/api/machine_requests';
import { saveError } from 'actions/form.base.actions';

export const saveMachineMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SAVE_FORM") {
    return next(action)
  }

  const machine = action.form;

  const payload = {
    ...machine.values
  }

  delete payload.id;

  let request = undefined;

  if(machine.values.id === undefined) {
    request = createMachine(payload)
  } else {
    request = updateMachine(machine.values.id, payload)
  }

  request
    .then(res => {
      if (res.status === 422) {
        dispatch(saveError(res))
      } else {
        window.location.href = '/technician/machines';
      }
    })
    .catch(err => dispatch(saveError(err)));
};
