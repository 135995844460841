import { present, runValidations } from '../../base.validations';

const attributeValidators = {
  code: [present],
  name: [present],
  growth_method: [present]
}

export function machineValidations(machine) {
  const errors = runValidations(machine, attributeValidators);

  if (machine.properties.machineProperties.length === 0 || machine.properties.controls.length === 0 || machine.properties.results.length === 0){
    errors['properties'] = "A complete set of machine defaults is required";
  } else {
    ['machineProperties', 'controls', 'results'].forEach(property => { 
      machine.properties[property].forEach(defaultProperty => {
        if (defaultProperty.name === '' || defaultProperty.name === undefined) {
            errors['properties'] = "A complete set of machine defaults is required";
          }
        })
    })
  }
  
  return errors;
}
