export const Layers = (state = [], action) => {
  switch (action.type) {
    case 'TOGGLE_BULK_EDITING_LAYER': {
      return state.map((layer) => {
        if (layer.id === action.layerId) {
          return { ...layer, selected: !layer.selected };
        } else {
          return layer;
        }
      });
    }
    default: {
      return state;
    }
  }
}
