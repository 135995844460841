import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addNewProperty, updateProperty, removeProperty } from 'actions/MachineManagementPage';
import { updateFormValue, saveForm } from 'actions/form.base.actions';

import Button from 'components/forms/Button';
import TextInput from 'components/forms/TextInput';
import SelectInput from 'components/forms/SelectInput';
import PropertyFields from './PropertyFields';

class MachineForm extends React.Component {
  handleSaveSubmit = (e) => {
    e.preventDefault();
    this.props.saveForm(this.props.form, {});
  }

  handlePropertyInput = (value, propertyType, index, key) => {
    this.props.updateProperty(value, propertyType, index, key);
  }

  render() {
    const { errors, formSaving, handleFormUpdate, saveForm, addNewProperty, updateProperty, removeProperty, ...values } = this.props;

    return (
      <form className="form-bordered" noValidate onSubmit={ this.handleSaveSubmit }>
        <div className="row">
          <div className="col-4">
            <TextInput label="Name" value={ values.name } name="name" handleInputChange={ (e) => handleFormUpdate('name', e.target.value) } errors={ errors['name'] } />
            <TextInput label="Code" value={ values.code } name="code" handleInputChange={ (e) => handleFormUpdate('code', e.target.value) } errors={ errors['code'] } />
            <SelectInput label="Growth Method" value={ values.growth_method } name="growth_method" handleInputChange={ (e) => handleFormUpdate('growth_method', e.target.value) } errors={ errors['growth_method'] } collection={this.props.collections['growth_method_collection']} required={true}/>
          </div>
        </div>

        <div className="property-list">
          <PropertyFields propertyTypeName="Properties" propertyType="machineProperties" properties={ values.properties.machineProperties } handlePropertyInput={ this.handlePropertyInput } handleNewProperty={ addNewProperty } handleRemoveProperty={ removeProperty } />
          <PropertyFields propertyTypeName="Controls" propertyType="controls" properties={ values.properties.controls } handlePropertyInput={ this.handlePropertyInput } handleNewProperty={ addNewProperty } handleRemoveProperty={ removeProperty } />
          <PropertyFields propertyTypeName="Results" propertyType="results" properties={ values.properties.results } handlePropertyInput={ this.handlePropertyInput } handleNewProperty={ addNewProperty } handleRemoveProperty={ removeProperty } />
        </div>
        {errors['properties'] && <div className='alert alert-warning'>{errors['properties']}</div>}
        <div className="row">
          <div className="col-4">
            <div className='form-actions mt-4'>
              <a href='/technician/machines' className= 'btn btn-secondary'>
                <i className='fas fa-arrow-left fa-fw'></i>
                Back
              </a>
              <Button icon='save' text='Save machine' className='btn-primary float-right' type='submit' disabled={ formSaving } />
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  const { form: { values, errors, saving } } = state;

  return {
    form: state.form,
    errors,
    formSaving: saving,
    ...values
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate: updateFormValue,
    saveForm,
    addNewProperty,
    updateProperty,
    removeProperty
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineForm)
