import React from 'react';

import Button from 'components/forms/Button';

export default function PropertyField({ name, defaultValue, handlePropertyInput, handleRemoveProperty }) {
  return (
    <div className='property-group'>
      <input type="string" placeholder="Property" className="form-control property-group-name" value={name} onChange={(e) => handlePropertyInput(e.target.value, 'name')} />
      <input type="string" placeholder="Default value" className="form-control property-group-value" value={defaultValue} onChange={(e) => handlePropertyInput(e.target.value, 'defaultValue')} />
      <Button icon='trash' className='btn btn-outline-danger' onClickHandler={handleRemoveProperty} />
    </div>
  )
}
