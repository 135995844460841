export const addNewProperty = (propertyType) => ({
  type: 'NEW_PROPERTY',
  propertyType
});

export const updateProperty = (value, propertyType, index, key) => ({
  type: 'UPDATE_PROPERTY',
  propertyType,
  value,
  index,
  key
})

export const removeProperty = (propertyType, index) => ({
  type: 'REMOVE_PROPERTY',
  propertyType,
  index
});
