import { postRequest, patchRequest, deleteRequest } from './base_requests';

export function createLayer(growth_request_id, growth_structure_id, layer) {
  return postRequest({ url: `/api/growth_requests/${growth_request_id}/growth_structures/${growth_structure_id}/layers`, body: { layer }})
}

export function createRunLayer(growth_run_id, layer) {
  return postRequest({ url: `/api/growth_runs/${growth_run_id}/layers`, body: { layer } })
}

export function updateLayer(layerId, layer) {
  return patchRequest({ url: `/api/layers/${layerId}`, body: { layer } })
}

export function deleteLayer(layerId) {
  return deleteRequest({ url: `/api/layers/${layerId}` })
}

export function moveLayer(layerId, endPosition) {
  return patchRequest({ url: `/api/layers/${layerId}/move_layer`, body: { new_position: endPosition } })
}

export function updateMachineSettingsForGrowth(growthRunID, selectedLayers, machineSettings) {
  return patchRequest({ url: `/api/growth_runs/${growthRunID}/update_machine_settings`, body: { growth_run: { selected_layers: selectedLayers, machine_settings: machineSettings } } } )
}
