import React from 'react';
import { Dropdown } from 'react-bootstrap';

const SplitDropdown = ({ handleSplit, handleDropdownToggle, id}) => {
  return (
    <Dropdown onToggle={(isOpen) => handleDropdownToggle(isOpen)}>
      <Dropdown.Toggle variant="outline-secondary" id={id} className='btn-sm'>
        <i className={`fas fa-cut fa-fw`}></i>
        <span className='sr-only'>Split this substrate</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleSplit(2)}>2 parts</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSplit(4)}>4 parts</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SplitDropdown;
