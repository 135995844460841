import { generateReportRequest } from 'services/api/report_requests';
import { reportRequestSent, reportRequestSuccess, reportRequestFailure } from 'actions/CustomerReportBuilderPage';

export const getReport = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SUBMIT_REPORT") {
    return next(action)
  }

  const state = getState().report;
  let payload = { documents: { ...state.documents } };
  
  Object.entries(payload.documents).forEach(([runId, documents]) => {
    payload.documents[runId] = { ...payload.documents[runId] }
    payload.documents[runId].draggable = [...documents.draggable].filter(document => document.checked).map(document => document.id)
    payload.documents[runId].non_draggable = [...documents.non_draggable].filter(document => document.checked).map(document => document.id)
  });

  payload.growth_run_ordering = state.growth_runs.filter(growth_run => growth_run.checked).map(growth_run => growth_run.id)
  
  dispatch(reportRequestSent());
  
  generateReportRequest(state.data.id, payload).
    then(async (response) => {
      if (response.status === 200) {
        let blob = await response.blob();

        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `growth_structure_${state.selectedStructure.reference_number}_report.pdf`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);

        dispatch(reportRequestSuccess());
      } else {
        dispatch(reportRequestFailure("There was an error downloading the document. Please try again, or contact support."));
      }
    })
    .catch((error) => {
      dispatch(reportRequestFailure("There was an error downloading the document. Please try again, or contact support."));
      console.log(error);
    });
};
