export function getSelectedElementsFromGroups(groups) {
  return Object.keys(groups).map(groupName => {
    return getSelectedElementsFromGroup(groups[groupName]);
  }).flat()
}

export function getSelectedElementsFromGroup(group) {
  return Object.values(group.elements).filter( element => element.selected );
}

export function totalPercentageForGroup(group){
  return getSelectedElementsFromGroup(group).reduce((total, element) => {
    return (total + (parseFloat(element.percentage) || 0 ))
  }, 0)
}

export function isGroupSelected(group) {
  return getSelectedElementsFromGroup(group).length > 0
}

export function disableIncompatibleGroups(groups) {
  const selectedElements = ['III', 'IV', 'V'].reduce((accumulator, groupName) => {
    accumulator[groupName] = Object.values(groups[groupName]['elements']).filter((element) => element['selected'])
    return accumulator;
  }, {})

  if (selectedElements['III'].length > 0 || selectedElements['V'].length > 0) {
    groups['IV']['disabled'] = true
  } else {
    groups['IV']['disabled'] = false
  }

  if (selectedElements['IV'].length > 0) {
    groups['III']['disabled'] = true
    groups['V']['disabled'] = true
  } else {
    groups['III']['disabled'] = false
    groups['V']['disabled'] = false
  }
  return groups;
}

export function setDefaultPercentage(elements) {
  const selectedElementsCount = Object.values(elements).filter(element => element.selected).length

  Object.keys(elements).forEach(element => {
    if(selectedElementsCount === 1) {
      if (elements[element].selected && (elements[element].percentage === '')) {
        elements[element].percentage = 100;
      }
    } else {
      if (elements[element].selected && (elements[element].percentage === 100)) {
        elements[element].percentage = '';
      }
    }
  });

  return elements;
}

export function baseGroupsCollection() {
  return {
    'III': {
      name: 'III',
      disabled: false,
      elements: {
      'Al': { name: 'Al', selected: false, percentage: '', group: 'III' },
      'Ga': { name: 'Ga', selected: false, percentage: '', group: 'III' },
      'In': { name: 'In', selected: false, percentage: '', group: 'III' }
    }},
    'IV': {
      name: 'IV',
      disabled: false,
      elements: {
      'Si': { name: 'Si', selected: false, percentage: '', group: 'IV' },
      'Ge': { name: 'Ge', selected: false, percentage: '', group: 'IV' },
      'Sn': { name: 'Sn', selected: false, percentage: '', group: 'IV' }
    }},
    'V': {
      name: 'V',
      disabled: false,
      elements: {
      'N': { name: 'N', selected: false, percentage: '', group: 'V' },
      'P': { name: 'P', selected: false, percentage: '', group: 'V' },
      'As': { name: 'As', selected: false, percentage: '', group: 'V' },
      'Sb': { name: 'Sb', selected: false, percentage: '', group: 'V' },
      'Bi': { name: 'Bi', selected: false, percentage: '', group: 'V' }
    }}
  }
}

export function defaultFormValues() {
  return {
    values: {
      repeats:                  '1',
      material_tolerance:       '',
      material_tolerance_unit:  'other',
      thickness:                '',
      thickness_unit:           'nanometer',
      thickness_tolerance:      '',
      thickness_tolerance_unit: 'other',
      doping_level:             '',
      doping_level_unit:        'per_cm3',
      doping_type:              'undoped',
      doping_tolerance:         '',
      doping_tolerance_unit:    'other',
      target_wavelength:        '',
      target_wavelength_unit:   'nanometer',
      wavelength_tolerance:     '',
      wavelength_tolerance_unit: 'nanometer'
    },
    errors: {},
    groups: baseGroupsCollection()
  }
}
