export const baseFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_FORM_VALUE': {
      let newState = { ...state };

      newState['values'][action.name] = action.value;
      return newState;
    }
    case 'SAVE_START': {
      return {
        ...state,
        saving: true
      }
    }
    case 'SAVE_SUCCESS': {
      return {
        ...state,
        type: undefined,
        saving: false
      };
    }
    case 'SAVE_FAILURE': {
      return {
        ...state,
        errors: action.errors
      };
    }
    case 'CANCEL_EDIT': {
      return {
        ...state,
        selectedLayer: undefined,
        type: undefined,
        saving: false
      };
    }
    default:
      return state;
  }
}
