import { saveError } from 'actions/form.base.actions';
import { machineValidations } from 'services/validations/technician/machines';

export const machineFormValidationMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SAVE_FORM") {
    return next(action)
  }

  const machine = action.form;

  let errors = { ...machineValidations(machine.values) }

  const anyErrors = Object.values(errors).some(errs => errs.length > 0);

  if(anyErrors) {
    dispatch(saveError(errors))
  } else {
    next(action)
  }
};