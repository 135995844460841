import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectSubstrate, splitSubstrate, bindSubstrate } from 'actions/SubstrateSelectionPage';
import Substrate from './Substrate';
import SelectedSubstrates from './SelectedSubstrates';
import Button from 'components/forms/Button';

class SubstrateList extends React.Component {

  handleSelectSubstrate = (substrate) => {
    this.props.selectSubstrate(this.props.growthRunId, substrate);
  }

  handleBindSubstrates = () => {
    this.props.bindSubstrate(this.props.growthRunId);
  }

  render() {
    const { substrates, selectedSubstrates, noSubstratesMessage } = this.props;
    if(substrates.length === 0) {
      return (
        <div className='pl-3'>
          <p className='text-muted'>{ noSubstratesMessage }</p>
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <div className='pl-3'>
            { selectedSubstrates.length > 0 &&
              <React.Fragment>
                <h3 className='mb-3 h5'>Selected substrates: </h3>
                <SelectedSubstrates substrates={selectedSubstrates} handleSubstrateToggle={this.handleSelectSubstrate} />

                <Button className="btn-primary"
                  onClickHandler={this.handleBindSubstrates}
                  icon="check"
                  text="Bind substrates"
                  confirm="Are you sure you're happy with the substrate selection?" />

                  <hr></hr>
              </React.Fragment>
            }
            <h3 className='mb-3 h5'>Available substrates: </h3>
            <div className='substrates'>
              {
                substrates.map((substrate, index) => (<Substrate key={substrate.id} substrate={substrate} index={index} handleSelectSubstrate={this.handleSelectSubstrate} handleSplitSubstrate={this.props.splitSubstrate} />))
              }
            </div>
          </div>
        </React.Fragment>
      )
    }
  }
}


const mapStateToProps = (state) => {
  return {
    substrates: state.substrates,
    selectedSubstrates: Object.values(state.selectedSubstrates)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    selectSubstrate: selectSubstrate,
    splitSubstrate: splitSubstrate,
    bindSubstrate: bindSubstrate
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstrateList)
