import React from 'react';
import I18n from 'locales/index.js.erb'
import { layerRepeats, sortElements, thicknessInNm, formatDopingLevel } from 'services/layers';

const LayerDetails = (layer) => {
  const { elements, repeats, doping_type, doping_level, doping_level_unit, doping_tolerance, doping_tolerance_unit, thickness_tolerance, thickness_tolerance_unit, material_tolerance, material_tolerance_unit, target_wavelength
,target_wavelength_unit, wavelength_tolerance, wavelength_tolerance_unit } = layer;

  return (
    <div className='layer-details'>
      {sortElements(elements).map((element, index) => {
        if(parseInt(element.percentage) === 100) {
          return (<React.Fragment key={index}>{element.name}</React.Fragment>);
        } else {
          return (<React.Fragment key={index}>{element.name}<sub>{parseFloat((parseFloat(element.percentage) / 100).toFixed(3))}</sub></React.Fragment>);
        }
      })}
      &nbsp;({thicknessInNm(layer)}nm) {layerRepeats(repeats)}

      { doping_type !== 'undoped' && (
        <div className='layer-params small'>Doping Type: {I18n.t(`enums.growth_layer.doping_type.${doping_type}`)}
        <div className='layer-params small'>Level: {doping_level_unit === 'per_cm3' ? formatDopingLevel(doping_level): doping_level} ({I18n.t(`enums.growth_layer.doping_level_unit.${doping_level_unit}`)})</div>
        </div>
        ) }
      {doping_tolerance && (
        <div className='layer-params small'>Doping Tolerance: {doping_tolerance} ({I18n.t(`enums.growth_layer.doping_tolerance_unit.${doping_tolerance_unit}`)})</div>
      )}

      { thickness_tolerance && (
        <div className='layer-params small'>Thickness Tolerance: {thickness_tolerance} ({I18n.t(`enums.growth_layer.thickness_tolerance_unit.${thickness_tolerance_unit}`)})</div>
      ) }

      {material_tolerance && (
        <div className='layer-params small'>Material Tolerance: {material_tolerance} ({I18n.t(`enums.growth_layer.material_tolerance_unit.${material_tolerance_unit}`)})</div>
      )}

      {target_wavelength && (
        <div className='layer-params small'>Target Wavelength: {target_wavelength} ({I18n.t(`enums.growth_layer.target_wavelength_unit.${target_wavelength_unit}`)})</div>
      )}

      {wavelength_tolerance && (
        <div className='layer-params small'>Wavelength Tolerance: {wavelength_tolerance} ({I18n.t(`enums.growth_layer.wavelength_tolerance_unit.${wavelength_tolerance_unit}`)})</div>
      )}
    </div>
  );
}

export default LayerDetails;
