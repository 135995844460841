export const selectSubstrate = (growthRunId, substrate) => ({
  type: 'SELECT_SUBSTRATE',
  growthRunId,
  substrate
});

export const bindSubstrate = (growthRunId) => ({
  type: 'BIND_SUBSTRATE',
  growthRunId
});

export const splitSubstrate = (substrateId, currentPosition, splitFactor) => ({
  type: 'SPLIT_SUBSTRATE',
  substrateId,
  currentPosition,
  splitFactor
});

export const splitSuccess = (oldSubstratePosition, newSubstrates) => ({
  type: 'SPLIT_SUBSTRATE_SUCCESS',
  oldSubstratePosition,
  newSubstrates
});
