import React from 'react';
import Button from 'components/forms/Button';
import CharacterisationForm from './CharacterisationForm';
import I18n from 'locales/index.js.erb';

const Characterisation = ({ characterisation, collections, selectedCharacterisation, handleEditCharacterisation, handleDeleteCharacterisation, editable }) => {
  if (selectedCharacterisation === characterisation.id) {
    return (<CharacterisationForm collections={collections}/>);
  } else {
    return (
      <div className="characterisation">
        <dl className="mb-0">
          <dt>Method: </dt>
          <dd>{I18n.t(`enums.growth_characterisation.characterisation_method.${characterisation.characterisation_method}`)}</dd>

          <dt>Purpose: </dt>
          <dd>{characterisation.purpose}</dd>
        </dl>
        {
          editable && (
            <div className="btn-toolbar">
              <Button className='btn-outline-secondary btn-sm' icon='edit' iconVariant='far' onClickHandler={() => handleEditCharacterisation(characterisation) } text="Edit"/>
              <Button className='btn-outline-danger btn-sm' icon='trash' iconVariant='far' onClickHandler={() => handleDeleteCharacterisation(characterisation.id) } text="Delete" confirm="Are you sure you want to delete this characterisation?"/>
            </div>
          )
        }
      </div>
    );
  }
}

export default Characterisation;
