export const SelectedSubstratesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_SUBSTRATE': {
      let newState = { ...state };

      if (newState[action.substrate.id])
        delete newState[action.substrate.id]
      else
        newState[action.substrate.id] = action.substrate;

      return newState;
    }

    default:
      return state;
  }
}
