import React from 'react';
import LayerDetails from 'components/pages/customers/growth_structures/layers/LayerDetails';

const Layer = ({number, layer, selectable=false, handleSelectLayer}) => {
  return (
    <div className={`layer ${selectable && 'selectable'}`} onClick={() => selectable && handleSelectLayer(layer.id)}>
      <div className='layer-content'>
        {
          selectable && (
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id={`layer-${layer.id}`} checked={layer.selected || false} onChange={() => handleSelectLayer(layer.id)}  />
              <label className="custom-control-label" htmlFor={`layer-${layer.id}`} ><span className="sr-only">Select layer {number}</span></label>
            </div>
          )
        }
        {number}. <LayerDetails {...layer} />
      </div>
    </div>
  );
}

export default Layer;
