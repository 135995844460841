export const AlertsReducer = (state = [], action) => {
  switch (action.type) {
    case 'NEW_ALERT': {
      return [...state, { variant: action.variant, content: action.content, timestamp: Date.now() }];
    }
    case 'DISMISS_ALERT': {
      const newState = [...state]
      newState.splice(state.findIndex((a) => a.timestamp == action.timestamp), 1);
      return newState;
    }
  }

  return state;
}