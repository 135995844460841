import React from 'react';
import Button from './Button';

export default function TextInputGroup({ label=undefined, value='', name, type="text", wrapperClassName='', inputClassName='', required=false, handleInputChange, errors=[], handleButtonClick, buttonText='' }) {
  const hasErrors = errors.length > 0;
  return (
    <div className={`${wrapperClassName} input-group`}>
      <div className={`${label !== undefined ? 'form-label-group' : 'form-group '} ${hasErrors ? 'form-group-invalid' : ''}`}>
          <input
            name={name}
            id={name}
            className={`form-control ${inputClassName} ${hasErrors ? 'is-invalid' : ''}`}
            required={required}
            aria-required={required}
            type={type}
            value={value}
            onChange={handleInputChange}
            placeholder={label} />

          { label && <label htmlFor={name} className='form-control-label'>{label}</label> }
          { errors.length > 0 && <div className='invalid-feedback'>{errors.join(', ')}</div> }
        </div>
      <div className="input-group-append">
        <Button className="float-right btn-primary" text={buttonText} icon="plus" onClickHandler={() => handleButtonClick()} />
      </div>
    </div>
  );
}
