import { baseFormReducer } from 'reducers/form.base.reducer';

const defaultState = {
  searching: false,
  filters_provided: false,
  values: {

  }
}

export const Form = (state = defaultState, action) => {
  state = baseFormReducer(state, action);

  switch (action.type) {
    case 'START_SEARCHING': {
      return {  ...state, searching: true }
    }

    case 'FINISH_SEARCHING': {
      return {  ...state, searching: false, filters_provided: true }
    }

    case 'SEARCH_FILTERS_REQUIRED': {
      return {  ...state, searching: false, filters_provided: false }
    }
    default:
      return state;
  }
}
