import React from 'react';
import Button from 'components/forms/Button';
import SplitDropdown from 'components/forms/SplitDropdown';

class Substrate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      splitMenuOpen: false
    }
  }

  handleDropdownToggle = (isOpen) => {
    this.setState({
      splitMenuOpen: isOpen
    })
  }

  splitSubstrate = (splitFactor) => {
    const { substrate, index, handleSplitSubstrate } = this.props;
    handleSplitSubstrate(substrate.id, index, splitFactor);
  }

  render() {
    const { substrate, selected, handleSelectSubstrate } = this.props;

    return (
      <div className={`substrate-group ${this.state.splitMenuOpen && 'menu-shown'} ${selected ? 'primary-bg' : ''}`} key={substrate.id} id={`substrate_${substrate.id}`}>
        <div className='substrate-serial-number'>
          {substrate.serial_number}
        </div>
        { <div className='btn-group substrate-actions' role='group'>
          {!selected && substrate.splitable && (<SplitDropdown handleSplit={this.splitSubstrate} handleDropdownToggle={(isOpen) => this.handleDropdownToggle(isOpen) } id={substrate.id} />) }
          <Button className={`btn-${selected ? 'secondary' : 'primary'} btn-sm`} id={`select_substrate_${substrate.id}`} icon={`${selected ? 'times' : 'check'}`} onClickHandler={() => handleSelectSubstrate(substrate)} />
        </div> }

      </div>
    );
  }
}

export default Substrate;
