import React from 'react';
import I18n from 'locales/index.js.erb'

const GrowthRequestDetails = ({ growth_structure }) => {
  return (
    <div className="substrate-details">
      <legend>Customer Order:</legend>
      <dl className="row">
        <dt className="col-md-6">Size:</dt>
        <dd className="col-md-6">
          { growth_structure.size === null ? 'Not provided' : I18n.t(`enums.substrate_properties.size.${growth_structure.size}`)}
        </dd>

        <dt className="col-md-6">Material Type:</dt>
        <dd className="col-md-6">
          { growth_structure.material_type === null ? 'Not provided' : I18n.t(`enums.substrate_properties.material_type.${growth_structure.material_type}`)}
        </dd>

        <dt className="col-md-6">Doping Type:</dt>
        <dd className="col-md-6">
          { growth_structure.doping_type === null ? 'Not provided' : I18n.t(`enums.substrate_properties.doping_type.${growth_structure.doping_type}`)}
        </dd>

        <dt className="col-md-6">Crystal Orientation:</dt>
        <dd className="col-md-6">
          { growth_structure.crystal_orientation === null ? 'Not provided' : I18n.t(`enums.substrate_properties.crystal_orientation.${growth_structure.crystal_orientation}`)}
        </dd>

        <dt className="col-md-6">Offcut Orientation:</dt>
        <dd className="col-md-6">
          { growth_structure.offcut_orientation === null ? 'Not provided' : I18n.t(`enums.substrate_properties.offcut_orientation.${growth_structure.offcut_orientation}`)}
        </dd>

        <dt className="col-md-6">Polish:</dt>
        <dd className="col-md-6">
          { growth_structure.polish === null ? 'Not provided' : I18n.t(`enums.substrate_properties.polish.${growth_structure.polish}`)}
        </dd>

        <dt className="col-md-6">Offcut Angle:</dt>
        <dd className="col-md-6">
          {growth_structure.offcut_angle === null ? 'Not provided' : I18n.t(`enums.substrate_properties.offcut_angle.${growth_structure.offcut_angle}`)}
        </dd>
      </dl>
    </div>
  )
}

export default GrowthRequestDetails;