import { baseFormReducer } from 'reducers/form.base.reducer';
import 'lodash';

const defaultState = {
  saving: false
}

export const Form = (state = defaultState, action) => {
  state = baseFormReducer(state, action);

  switch (action.type) {
    case 'NEW_PROPERTY': {
      let newState = _.cloneDeep(state);

      newState.values.properties[action.propertyType] = [...newState.values.properties[action.propertyType], { name: '', defaultValue: ''}];

      return newState;
    }
    case 'UPDATE_PROPERTY': {
      let newState = _.cloneDeep(state);
      newState.values.properties[action.propertyType][action.index][action.key] = action.value;

      return newState;
    }
    case 'REMOVE_PROPERTY': {
      let newState = _.cloneDeep(state);

      newState.values.properties[action.propertyType].splice(action.index, 1)

      return newState;
    }
    default:
      return state;
  }
}
