export function defaultFormValues() {
  return {
    values: {
      characterisation_method: '',
      purpose: ''
    },
    errors: {}
  }
}

export function buildExistingCharacterisationForForm(characterisation) {
  let formState = defaultFormValues();
  formState.values = { ...formState.values, ...characterisation }

  return formState;
}
