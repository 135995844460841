import React from 'react';

import SentryErrorBoundary from '../../../Sentry/SentryErrorBoundary';
import StocksForm from '../../../staff/stocks/StockForm';

const StockManagement = (props) => {
  return (
    <SentryErrorBoundary>
      <StocksForm {...props} />
    </SentryErrorBoundary>
  )
}

export default StockManagement;
