import React from 'react';
import Button from '../../forms/Button';
import TextInput from '../../forms/TextInput';
import SelectInput from '../../forms/SelectInput';

class BatchFields extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, batch, errors={}, handleRemove, handleInputChange } = this.props;

    return (
      <div className='nested-fields batch'>
        <div className='row batchHeader'>
          <div className="col-md-6 col-xl-4">
            <TextInput label="Batch Number" value={batch.batch_number} name="batch_number" handleInputChange={handleInputChange} errors={errors['batch_number']} />
          </div>

          <div className="col-md-6 col-xl-3">
            <TextInput label="Starting Number" value={batch.starting_number} name="starting_number" handleInputChange={handleInputChange} errors={errors['starting_number']} type="number" />
          </div>

          <div className='col-md-6 col-xl-3'>
            <TextInput label="Quantity" value={batch.quantity} name="quantity" handleInputChange={handleInputChange} errors={errors['quantity']} type="number" />
          </div>

          <div className='col-md-6 col-xl-2 d-flex mb-3'>
            <Button className="btn-outline-danger btn-sm ml-auto align-self-center" icon="trash" onClickHandler={handleRemove} text="Remove" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SelectInput label="Substrate Size" value={batch.size} name="size" handleInputChange={handleInputChange} errors={errors['size']} collection={this.props.substrate_size_collection} required={true} />
          </div>

          <div className="col-md-4">
            <SelectInput label="Material Type" value={batch.material_type} name="material_type" handleInputChange={handleInputChange} errors={errors['material_type']} collection={this.props.material_type_collection}  required={true} />
          </div>

          <div className="col-md-4">
            <TextInput label="Thickness" value={batch.thickness} name="thickness" handleInputChange={handleInputChange} errors={errors['thickness']} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SelectInput label="Growth Type" value={batch.growth_type} name="growth_type" handleInputChange={handleInputChange} errors={errors['growth_type']} collection={this.props.growth_type_collection}  required={true} />
          </div>

          <div className="col-md-4">
            <SelectInput label="Doping Type" value={batch.doping_type} name="doping_type" handleInputChange={handleInputChange} errors={errors['doping_type']} collection={this.props.doping_type_collection}  required={true} />
          </div>

          <div className="col-md-4">
            <TextInput label="Doping Level" value={batch.doping_level} name="doping_level" handleInputChange={handleInputChange} errors={errors['doping_level']} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <SelectInput label="Crystal Orientation" value={batch.crystal_orientation} name="crystal_orientation" handleInputChange={handleInputChange} errors={errors['crystal_orientation']} collection={this.props.crystal_orientation_collection}  required={true} />
          </div>

          <div className="col-md-4">
            <SelectInput label="Offcut Orientation" value={batch.offcut_orientation} name="offcut_orientation" handleInputChange={handleInputChange} errors={errors['offcut_orientation']} collection={this.props.offcut_orientation_collection}  required={true} />
          </div>

          <div className="col-md-4">
            <SelectInput label="Polish" value={batch.polish} name="polish" handleInputChange={handleInputChange} errors={errors['polish']} collection={this.props.polish_collection}  required={true} />
          </div>
        </div>
      
        <div className="row">
          <div className="col-md-4">
            <SelectInput label="Offcut Angle" value={batch.offcut_angle} name="offcut_angle" handleInputChange={handleInputChange} errors={errors['offcut_angle']} collection={this.props.offcut_angle_collection} required={true} />
          </div>
        </div>

      </div>
    )
  }
}

export default BatchFields;
