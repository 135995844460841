import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';

import { AlertsReducer } from 'reducers/Alerts';

import Alerts from 'components/Alerts/Alerts';
import SentryErrorBoundary from '../../../Sentry/SentryErrorBoundary';
import SubstrateManagementForm from '../../../staff/stocks/SubstrateManagementForm';

const SubstrateManagement = (props) => {
  const store = createStore(
    combineReducers({ alerts: AlertsReducer })
  );

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <SubstrateManagementForm {...props} />
        <Alerts />
      </Provider>
    </SentryErrorBoundary>
  )
}

export default SubstrateManagement;
