import React from 'react';
import ValidationErrors from './ValidationErrors';

export default function FormField({ children, ...props }) {
  const { wrapperClassName='', errors=[], label } = props;

  const hasErrors = errors.length > 0;
  const className = `${label !== undefined ? 'form-label-group' : 'form-group'} ${wrapperClassName} ${hasErrors ? 'form-group-invalid' : ''}`;

  return (
    <div className={className}>
      { children }
      <ValidationErrors errors={errors} />
    </div>
  );
}
