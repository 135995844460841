import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DraggableLayer = ({layer, index, editingType, editable=true, provided={}, snapshot, children}) => {
  const draggable = (editingType === undefined) && editable;
  return (
    <Draggable draggableId={`${layer.id}`} index={index} isDragDisabled={!draggable}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`draggable-layer ${snapshot.isDragging ? 'dragging' : ''}`}>
        { React.cloneElement(children, { draggable: draggable, dragHandleProps: provided.dragHandleProps }) }
        </div>
      )}
    </Draggable>
  );
}

export default DraggableLayer;
