import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { newLayer, editLayer, moveLayer, deleteLayer, duplicateLayer } from 'actions/LayersManagement';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getNonce } from 'scripts/nonce_helper';

import LayerForm from './LayerForm';
import Layer from './Layer';
import DraggableLayer from './DraggableLayer';
import Button from 'components/forms/Button';

import I18n from 'locales/index.js.erb'


const LayerList = ({ editingType, selectedLayer, layers, details, ...props }) => {
  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }
    props.moveLayer(result.source.index, result.destination.index);
  }

  return (
    <React.Fragment>
      { details.editable && (
        <Button className="btn-primary mb-3" onClickHandler={props.newLayer} text='Add a new layer on top' icon='layer-plus' />
      ) }

      { editingType === 'creating' && selectedLayer === 0 && (
        <LayerForm details={details} collections={props.collections} />
      )}

      <DragDropContext onDragEnd={onDragEnd} nonce={getNonce()}>
        <Droppable droppableId='layer-list' >
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={`layer-list ${editingType === 'editing' ? 'editing' : ''}`}>
              { layers.map((layer, index) =>(
                <DraggableLayer key={layer.id} draggableId={`${layer.id}`} index={index} layer={layer} editingType={editingType} editable={details.editable}>
                  <Layer number={layers.length - index} layer={layer} editable={details.editable} selectedLayer={selectedLayer} handleDeleteLayer={props.deleteLayer} handleEditLayer={props.editLayer} handleDuplicateLayer={props.duplicateLayer} collections={props.collections}/>
                </DraggableLayer>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={`layer substrate-layer ${editingType === undefined && details.editable && 'editable'}`}>
        <div className="layer-content">
          0. Substrate&nbsp;{details.substrate_material && (<span>({I18n.t(`enums.growth_structure.material_type.${details.substrate_material}`)})</span>) }
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    editingType: state.form.type,
    selectedLayer: state.form.selectedLayer,
    layers: state.layers
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    newLayer: newLayer,
    editLayer: editLayer,
    moveLayer: moveLayer,
    deleteLayer: deleteLayer,
    duplicateLayer: duplicateLayer
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerList)
