import { cloneDeep } from 'lodash';

export const Form = (state = {}, action) => {
  switch (action.type) {

    case 'TOGGLE_PROPERTY': {
      let newState = { ...state };
      const property = state.machineSettings[action.propertyType][action.propertyName.name];

      newState.machineSettings[action.propertyType][action.propertyName.name] = { ...property, visible: !property['visible'] };

      return newState;
    }

    case 'SET_PROPERTY': {
      let newState = { ...state };
      const property = state.machineSettings[action.propertyType][action.propertyName.name];

      newState.machineSettings[action.propertyType][action.propertyName.name] = { ...property, value: action.value };

      return newState;
    }

    case 'SELECT_LAYER_TO_EDIT': {
      return { ...state, selectedLayer: action.layerId, machineSettings: cloneDeep(action.machineSettings) }
    }

    case 'BULK_EDIT_SELECTED_LAYERS': {
      return { ...state, bulkEditing: true, selectedLayer: undefined, machineSettings: cloneDeep(action.machineSettings) }
    }

    case 'SAVE_FORM': {
      return { ...state, bulkEditing: false, selectedLayer: undefined, machineSettings: undefined }
    }

    case 'CANCEL_EDIT': {
      return { ...state, bulkEditing: false, selectedLayer: undefined, machineSettings: undefined }
    }


    default: {
      return state;
    }
  }
}
