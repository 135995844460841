import React from 'react';

import Button from '../../forms/Button';
import BatchFields from './BatchFields';
import TextInput from '../../forms/TextInput';
import FormActions from '../../forms/FormActions';
import SectionError from '../../forms/SectionError';

import { createStockRequest } from '../../../services/api/stock_requests';

class StockForm extends React.Component {
  constructor(props) {
    super(props);

    let stock = {};
    let currentDate = new Date();
    stock.received_at = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" +  currentDate.getDate();

    if(stock.batches === undefined || Object.keys(stock.batches).length === 0) {
      stock.batches = {}
      stock.batches[this.generateIdentifier()] = this.defaultBatchAttributes();
    }

    this.state = {
      stock,
      errors: {
        stock: {},
        batches: {}
      },
      form: {
        valid: Object.keys(stock.batches).length > 0,
        saving: false
      }
    }
  }

  defaultBatchAttributes() {
    return {
      id: null,
      batch_number: '',
      quantity: '',
      size: '',
      material_type: '',
      thickness: '',
      growth_type: '',
      doping_type: '',
      doping_level: '',
      crystal_orientation: '',
      offcut_orientation: '',
      polish: '',
      offcut_angle: ''
    }
  }

  generateIdentifier() {
    return new Date().getTime()
  }

  addBatch = () => {
    const currentTimestamp = this.generateIdentifier();
    let { stock } = this.state;
    stock.batches[currentTimestamp] = this.defaultBatchAttributes(currentTimestamp);

    this.setState({ stock });
  }

  removeBatch = (batchId) => {
    let { stock, stock: { batches }} = this.state;
    delete batches[batchId]

    this.setState({stock, ...{ batches }})
  }

  saveForm = () => {
    this.setState({ form: { saving: true } })

    createStockRequest(this.state.stock).then(async (res) => {
      const resData = await res.json();
      this.setState({ form: { saving: false } })

      if (res.status == 422) {
        this.setState({ errors: resData })
      } else {
        window.location.href = `/admin/stocks/${resData.stock_id}/management`
      }
    }).catch(errs => console.error(errs));
  }

  handleInputChange = (e) => {
    let { stock } = this.state;
    stock[e.target.name] = e.target.value;
    this.setState({ stock })
  }

  handleBatchInputChange = (e, batchIdentifier) => {
    let { stock, stock: { batches }} = this.state;

    batches[batchIdentifier][e.target.name] = e.target.value

    this.setState({ stock, ...{ batches }});
  }

  render() {
    const { stock, errors, stock: { batches } } = this.state;

    const batchesToRender = Object.keys(batches).map((batchIdentifier) =>
      <BatchFields handleInputChange={(e) => this.handleBatchInputChange(e, batchIdentifier)} handleRemove={() => this.removeBatch(batchIdentifier)} batch={batches[batchIdentifier]} key={batchIdentifier} errors={errors['batches'][batchIdentifier]} {...this.props} />
    )

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-4">
            <TextInput label="Received At" value={stock.received_at} errors={errors.stock.received_at} name="received_at" handleInputChange={this.handleInputChange} type="date" />
          </div>
        </div>

        <fieldset>
          <legend>Stock batches</legend>
          { errors.stock.batch_forms && <SectionError errors={errors.stock.batch_forms} /> }
          { batchesToRender }
          <Button className="btn-primary btn-sm" icon="plus" text="Add Batch" onClickHandler={this.addBatch} />

          <FormActions saveHandler={this.saveForm} backUrl='/admin/stocks' disabled={this.state.form.saving}/>

        </fieldset>
      </React.Fragment>
    );
  }
}

export default StockForm;
