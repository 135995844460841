import { deleteSuccess, deleteError } from 'actions/CharacterisationsPage';
import { deleteCharacterisation } from 'services/api/characterisation_requests';

export const deleteCharacterisationMiddleWare = ({ dispatch, getState }) => next => action => {
  if(action.type !== "DELETE_CHARACTERISATION") {
    return next(action)
  }

  const { characterisationId } = action;

  deleteCharacterisation(characterisationId)
    .then(res => dispatch(deleteSuccess(characterisationId)))
    .catch(err => dispatch(deleteError(err)));
};
