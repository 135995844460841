import { defaultFormValues, buildExistingCharacterisationForForm } from 'services/characterisations';
import { baseFormReducer } from 'reducers/form.base.reducer';

const defaultState = {
  saving: false,
  type: undefined,
  selectedCharacterisation: undefined,
  values: {
    characterisation_method: '',
    purpose: ''
  },
  errors: {}
}

export const Form = (state = defaultState, action) => {
  state = baseFormReducer(state, action);

  switch (action.type) {
    case 'CANCEL_EDIT': {
      return {
        ...state,
        selectedCharacterisation: undefined
      };
    }
    case 'SAVE_SUCCESS': {
      return {
        ...state,
        selectedCharacterisation: undefined
      };
    }
    case 'CHARACTERISATION_SAVE_FAILURE': {
      return {
        ...state,
        errors: action.errors
      };
    }
    case 'NEW_CHARACTERISATION': {
      let newState = defaultFormValues();

      newState.selectedCharacterisation = 0;
      newState.type = 'creating';

      return newState;
    }
    case 'SELECT_CHARACTERISATION': {
      let characterisation = { ...action.characterisation };

      let newState = buildExistingCharacterisationForForm(characterisation)

      newState.selectedCharacterisation = characterisation.id;
      newState.type = 'editing'

      return newState;
    }
    default:
      return state;
  }
}
