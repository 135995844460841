import React from 'react';

export default function SelectInput({ label=undefined, value='', name, wrapperClassName='', inputClassName='', required=false, handleInputChange, errors=[], collection=[], autoFocus=false }) {
  const hasErrors = errors.length > 0;

  const wrapperClasses = `select ${label !== undefined ? 'form-label-group' : 'form-group'} ${wrapperClassName} ${hasErrors ? 'form-group-invalid' : ''}`
  const selectClasses = `form-control ${inputClassName} ${hasErrors ? 'is-invalid' : ''}`

  return (
    <div className={wrapperClasses}>
      <select name={name} id={name} className={selectClasses} required={required} aria-required={required} value={value} onChange={handleInputChange} autoFocus={autoFocus}>
        <option></option>
        { collection.map((option) =>  <option key={option[1]} value={option[1]}>{option[0]}</option> ) }
      </select>
      { label && <label htmlFor={name} className='form-control-label'>{label}</label> }
      { errors.length > 0 && <div className='invalid-feedback'>{errors.join(', ')}</div> }
    </div>
  );
}
