import React from 'react';
import { connect } from 'react-redux';

import SubstrateSearchForm from './SubstrateSearchForm';
import SubstrateList from './SubstrateList';

import Alerts from 'components/Alerts/Alerts';

class SubstrateSelectionScreen extends React.Component {
  render() {
    return (
      <div>
        <SubstrateSearchForm collections={this.props.collections} />
        <SubstrateList growthRunId={this.props.growth_run_id} noSubstratesMessage={this.props.filters_provided ? 'No available substrates with the provided filters.' : 'Please select a filter to show substrates.'}/>
        <Alerts />
      </div>
    );
  }
}

// export default SubstrateSelectionScreen;
const mapStateToProps = (state) => {
  return {
    filters_provided: state.form.filters_provided
  };
};

export default connect(mapStateToProps)(SubstrateSelectionScreen)
