import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleElement, setPercentage } from 'actions/LayersManagement';
import { updateFormValue, cancelEdit, saveForm } from 'actions/form.base.actions';

import MaterialGroupFields from './MaterialGroupFields';
import TextInputWithUnits from 'components/forms/TextInputWithUnits';
import SelectInput from 'components/forms/SelectInput';
import Button from 'components/forms/Button';
import NumberInput from 'components/forms/NumberInput';

class LayerForm extends React.Component {
  handleSaveSubmit = (e) => {
    e.preventDefault();
    this.props.saveLayer(this.props.form, this.props.details)
  }

  render() {
    const { form, values={}, groups=[], errors={}, toggleElement, setPercentage, handleFormUpdate, cancelEdit, collections={} } = this.props;

    return(
      <form className="layer-form form-bordered" noValidate onSubmit={this.handleSaveSubmit}>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <NumberInput
              min='0'
              handleInputChange={(e) => handleFormUpdate('repeats', e.target.value)}
              label="Repeats"
              value={values.repeats}
              errors={errors.repeats}
              name="repeats"
              autoFocus={true} />
          </div>
        </div>
        <fieldset className={`mb-5 material-composition ${errors['group'] ? 'is-invalid' : ''}`}>
          <legend>Material Composition</legend>
          { groups.map((group) => <MaterialGroupFields
              key={group.name}
              group={group}
              toggleElement={toggleElement}
              setPercentage={setPercentage}
              errors={(errors && errors.groups) ? errors.groups[group.name] : undefined} />) }
          { errors['group'] && <div className="invalid-feedback">{ errors['group'].join(', ') }</div> }
        </fieldset>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Thickness"
              value={values.thickness}
              errors={errors.thickness}
              name="thickness"
              unitValue={values.thickness_unit}
              availableUnits={collections['thickness_unit_collection']} />
          </div>
          <div className="col-xl-4 col-lg-6">
            <SelectInput
              handleInputChange={(e) => handleFormUpdate('doping_type', e.target.value)}
              label="Doping Type"
              value={values.doping_type}
              errors={errors.doping_type}
              name="doping_type"
              required={true}
              collection={collections['doping_type_collection']} />
          </div>
          <div className="col-xl-4 col-lg-6">
            <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Doping Level"
              value={values.doping_level}
              errors={errors.doping_level}
              name="doping_level"
              additionalClassName= 'doping-level-unit-select'
              unitValue={values.doping_level_unit}
              availableUnits={collections['doping_level_unit_collection']} />
          </div>

        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Thickness Tolerance"
              value={values.thickness_tolerance}
              errors={errors.thickness_tolerance}
              name="thickness_tolerance"
              additionalClassName= 'thickness-tolerance-unit-select'
              unitValue={values.thickness_tolerance_unit}
              availableUnits={collections['thickness_tolerance_unit_collection']} />
          </div>

          <div className="col-xl-4 col-lg-6">
            <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Doping Tolerance"
              value={values.doping_tolerance}
              errors={errors.doping_tolerance}
              name="doping_tolerance"
              additionalClassName= 'doping-tolerance-unit-select'
              unitValue={values.doping_tolerance_unit}
              availableUnits={collections['doping_tolerance_unit_collection']} />
          </div>

          <div className="col-xl-4 col-lg-6">
            <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Material Tolerance"
              value={values.material_tolerance}
              errors={errors.material_tolerance}
              name="material_tolerance"
              additionalClassName= 'material-tolerance-unit-select'
              unitValue={values.material_tolerance_unit}
              availableUnits={collections['material_tolerance_unit_collection']} />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-6">
          <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Target Wavelength"
              value={values.target_wavelength}
              errors={errors.target_wavelength}
              name="target_wavelength"
              additionalClassName= 'target-wavelength-unit-select'
              unitValue={values.target_wavelength_unit}
              availableUnits={collections['target_wavelength_unit_collection']} />
          </div>
          <div className="col-xl-4 col-lg-6">
          <TextInputWithUnits
              handleInputChange={handleFormUpdate}
              handleUnitUpdate={handleFormUpdate}
              label="Wavelength Tolerance"
              value={values.wavelength_tolerance}
              errors={errors.wavelength_tolerance}
              name="wavelength_tolerance"
              additionalClassName= 'wavelength-tolerance-unit-select'
              unitValue={values.wavelength_tolerance_unit}
              availableUnits={collections['wavelength_tolerance_unit_collection']} />
          </div>
        </div>
        <div className='form-actions d-flex mt-4'>
          <Button
            icon='times'
            text='Cancel edit'
            className='btn-secondary'
            onClickHandler={cancelEdit} />

          <Button
            icon='save'
            text='Save layer'
            className='btn-primary ml-auto'
            type='submit'
            disabled={form.saving} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form:   state.form,
    groups: Object.values(state.form.groups),
    values: state.form.values,
    errors: state.form.errors
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    toggleElement: toggleElement,
    setPercentage: setPercentage,
    handleFormUpdate: updateFormValue,
    saveLayer: saveForm,
    cancelEdit: cancelEdit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerForm)
