import React from 'react';

import Substrate from './Substrate';
import TextInputGroup from '../../forms/TextInputGroup';

const Batch = ({ batch, id, handleSubstrateRemove, handleSubstrateAdd, handleSubstrateSplit, handleNextSubstrateInput, values, errors }) => {
  const {
    batch_number,
    material_type,
    doping_level,
    doping_type,
    growth_type,
    polish,
    offcut,
    offcut_angle,
    orientation,
    size,
    thickness,
    stock_id
  } = batch;

  return (
    <div className='card batch' key={`batch_${id}`} id={`batch_${id}`}>
      <div className='card-header'>
        <div className="row">
          <h2 className="card-title col-md-6 align-self-center">Substrates in batch {batch_number}</h2>
          <TextInputGroup
            label='Serial Number'
            placeholder='Serial Number'
            name={`substrate_serial_number_${id}`}
            wrapperClassName="col-md-6"
            handleInputChange={(e) => handleNextSubstrateInput(e, id)}
            value={values.nextSubstrateValue}
            errors={errors.serial_number}
            handleButtonClick={() => handleSubstrateAdd(stock_id, id, values.nextSubstrateValue)}
            buttonText="Add new substrate to this batch" />
        </div>
          <div className='batch-details'>
            <div className='batch-property'><span className='batch-property-name'>Growth Type</span><span>{growth_type}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Material Type</span><span>{material_type}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Substrate Size</span><span>{size}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Orientation</span><span>{orientation}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Offcut</span><span>{offcut}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Offcut Angle</span><span>{offcut_angle}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Doping Type</span><span>{doping_type}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Doping Level</span><span>{doping_level}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Polish</span><span>{polish}</span></div>
            <div className='batch-property'><span className='batch-property-name'>Thickness</span><span>{thickness}</span></div>
          </div>
      </div>

      <div className="card-body substrates">
        { Object.values(batch.substrates).map((substrate) =>
          <Substrate key={`substrate_${substrate.id}`} handleRemove={(e, ref) => handleSubstrateRemove(id, substrate.id, ref)} handleSplit={handleSubstrateSplit} {...substrate} />
        ) }
      </div>
    </div>
  )
}

export default Batch;
