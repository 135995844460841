import { getSelectedElementsFromGroup } from 'services/layers';

export function materialCompositionValidations(groups) {
  let baseErrors = checkGroupsForSumError(groups);

  if(!checkGroupsForAnyGroupPresence(groups)) {
    baseErrors['group'] = ['must have at least one group selected']
  }

  return baseErrors;
}

function checkGroupsForSumError(groups) {
  return groups.reduce((errors, group) => {
    const selectedElements = getSelectedElementsFromGroup(group);

    if (selectedElements.length > 0 &&
        selectedElements.reduce((totalPercentage, element) => {
          return totalPercentage + parseFloat(element.percentage)
        }, 0) !== 100
      ) {
      errors['groups'][group.name] = ['group must total 100%']
    }
    return errors;
  }, { groups: {} });
}

function checkGroupsForAnyGroupPresence(groups) {
  return groups.some((group) => {
    return getSelectedElementsFromGroup(group).length > 0;
  })
}