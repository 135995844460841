import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleBulkEditingLayer, bulkEditSelectedLayers } from 'actions/GrowthRunManagementPage'

import Layer from './Layer';

import BulkEditMachineSettingForm from './BulkEditMachineSettingForm';
import MachineSettings from './MachineSettings';
import MachineSettingsForm from './MachineSettingsForm';

import Button from 'components/forms/Button';

import I18n from 'locales/index.js.erb';

const LayerList = ({ layers, machineSettings, details, bulkEditing, layerBeingEdited, handleSelectLayer, handleSelectLayerForBulkEditing, handleBulkEditSelectedLayers, ...props }) => {
  const bulkEditingLayers = layers.filter((layer) => layer.selected);

  return (
    <React.Fragment>
      <div className='btn-toolbar mb-3'>
        {
          (bulkEditingLayers.length > 0) &&
          (<Button className='btn-secondary  ml-auto' icon='edit' onClickHandler={handleBulkEditSelectedLayers} text="Change settings for selected layers"/>)
        }
      </div>

      { bulkEditing && <BulkEditMachineSettingForm />}

      { layers.map((layer, index) =>(
        <div className='machine-setting-group' key={layer.id} >
          <Layer number={layers.length - index} layer={layer} selectable={details.editable} handleSelectLayer={handleSelectLayerForBulkEditing} />
          { layerBeingEdited === layer.id ? <MachineSettingsForm layerId={layer.id} /> : <MachineSettings layerId={layer.id} editable = {details.editable}/> }
        </div>
      ))}

      <div className='machine-setting-group' >
        <div className="layer substrate-layer"><span className="layer-content">Initial Machine Settings</span></div>
        { layerBeingEdited === 'initial-settings' ? <MachineSettingsForm layerId={'initial-settings'} /> : <MachineSettings layerId={'initial-settings'} editable = {details.editable} /> }
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    layers: state.layers,
    layerBeingEdited: state.form.selectedLayer,
    bulkEditing: state.form.bulkEditing,
    machineSettings: state.machineSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleSelectLayerForBulkEditing: toggleBulkEditingLayer,
    handleBulkEditSelectedLayers: bulkEditSelectedLayers
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerList)
