import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import SelectInput from 'components/forms/SelectInput';
import TextInput from 'components/forms/TextInput';

import { updateFormValue } from 'actions/form.base.actions';
import { startSearching } from 'actions/SubstrateSelectionPage';

const SubstrateSearchForm = ({form, collections, values, errors, handleFormUpdate, handleSubmit}) => {
  const onMount = () => {
    handleSubmit(values);
  }
  useEffect(onMount, []);

  const debouncedSubmit = AwesomeDebouncePromise(handleSubmit, 500);

  const handleInputChange = (fieldName, value) => {
    handleFormUpdate(`search[${fieldName}]`, value);
    debouncedSubmit(values);
  }

  return (
    <form className='form-bordered pb-0 mb-3'>
      <h4 className='mb-3'>Filter substrate stock</h4> 
      <div className="row">

        <div className="col-md-4">
          <TextInput label="Batch & Wafer" value={values[`search[search_by_batch_and_wafer_number]`]} handleInputChange={(e) => handleInputChange('search_by_batch_and_wafer_number', e.target.value)} name="search_by_batch_and_wafer_number" placeholder={"Batch & Wafer"} autoFocus={true} required={true} />
        </div>
        
        <div className="col-md-4">
          <SelectInput label="Material Type" value={values[`search[material_type_eq]`]} handleInputChange={(e) => handleInputChange('material_type_eq', e.target.value)} name="material_type_eq" collection={collections['material_type_collection']}  required={true} />
        </div>

      </div>

      <div className="row">
        <div className="col-md-4">
          <SelectInput label="Offcut Angle" value={values[`search[offcut_angle_eq]`]} handleInputChange={(e) => handleInputChange('offcut_angle_eq', e.target.value)} name="offcut_angle_eq" collection={collections['offcut_angle_collection']} required={true} />
        </div>

        <div className="col-md-4">
          <SelectInput label="Substrate Size" value={values[`search[size_eq]`]} handleInputChange={(e) => handleInputChange('size_eq', e.target.value)} name="size_eq" collection={collections['substrate_size_collection']} required={true} />
        </div>

        <div className="col-md-4">
          <SelectInput label="Offcut Orientation" value={values[`search[offcut_orientation_eq]`]} handleInputChange={(e) => handleInputChange('offcut_orientation_eq', e.target.value)} name="offcut_orientation_eq" collection={collections['offcut_orientation_collection']}  required={true} />
        </div>

      </div>

      <div className="row">

        <div className="col-md-4">
          <SelectInput label="Polish" value={values[`search[polish_eq]`]} handleInputChange={(e) => handleInputChange('polish_eq', e.target.value)} name="polish_eq" collection={collections['polish_collection']}  required={true} />
        </div>

        <div className="col-md-4">
          <SelectInput label="Doping Type" value={values[`search[doping_type_eq]`]} handleInputChange={(e) => handleInputChange('doping_type_eq', e.target.value)} name="doping_type_eq" collection={collections['doping_type_collection']}  required={true} />
        </div>

        <div className="col-md-4">
          <SelectInput label="Crystal Orientation" value={values[`search[crystal_orientation_eq]`]} handleInputChange={(e) => handleInputChange('crystal_orientation_eq', e.target.value)} name="crystal_orientation_eq" collection={collections['crystal_orientation_collection']}  required={true} />
        </div>

      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    form:   state.form,
    values: state.form.values
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate: updateFormValue,
    handleSubmit: startSearching
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstrateSearchForm)
