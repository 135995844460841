import React from 'react';

const ElementInput = ({handleToggle, handleChange, disabled=false, name, group, selected, percentage}) => {
  const handleOnChange = (e) => {
    const value = (parseFloat(e.target.value) || 0);
    if (value >= 0 && value <= 100) {
      handleChange(name, group, e.target.value);
    }
  }

  return(
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id={`select-${name}`} disabled={disabled} checked={selected} onChange={(e) => handleToggle(name, group, e.target.checked)} />
          <label className="custom-control-label" htmlFor={`select-${name}`}><span className="sr-only">Select {name}</span></label>
        </div>
      </div>
      <div className="form-label-group">
        <input
          id={`percent-${name}`}
          min="0"
          max="100"
          className="form-control"
          required={true}
          value={(selected && !disabled) ? percentage : ''}
          onChange={handleOnChange}
          aria-required={true}
          disabled={disabled || !selected}
          type="number"
          placeholder={`${name} %`}
        />
        <label htmlFor={`percent-${name}`} className='form-control-label'>{name} %</label>
      </div>
    </div>
  )
}

export default ElementInput;
