import React from 'react';

import DocumentsForGrowthRun from './DocumentsForGrowthRun'

const DocumentsList = ({ growth_runs }) => {
  return (
    <React.Fragment>
      <h3>Choose documents and display order</h3>
      <p>Uncheck a document to exclude it from the report. The report is arranged with viewable images first which you can re-arrange into any desired order, followed by attached PDF's concatenated after.</p>
      { growth_runs.map(growth_run => <DocumentsForGrowthRun growth_run={growth_run} key={`growth_run_documents_${growth_run.id}`} /> ) }
    </React.Fragment>
  );
}

export default DocumentsList;