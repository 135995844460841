import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { selectLayerToEdit } from 'actions/GrowthRunManagementPage'

import Button from 'components/forms/Button';

const MachineSettings = ({machineSettings={}, layerId, handleSelectLayerForEdit, editable}) => {
  const settingsString =  Object.values(machineSettings).map(settings => Object.values(settings)).flat().filter(setting => setting.visible).map((setting) => {
    return `${setting.name}=${setting.value}`
  })

  return(
    <div className='machine-settings'>
      <div className='settings-string'>{settingsString.length > 0 ? settingsString.join(', ') : (<em className="text-muted">Not configured</em>)}</div>
      { editable && <Button className='btn-outline-secondary edit-machine-settings' icon='edit' onClickHandler={()=> handleSelectLayerForEdit(layerId, machineSettings)} text="Change"/> }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    machineSettings: state.machineSettings[ownProps.layerId]
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleSelectLayerForEdit: selectLayerToEdit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineSettings);
