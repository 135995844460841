import 'styles/select_grant';

import 'lodash';
import React from 'react';
import AddGrantButton from './AddGrantButton';
import ValidationError from './ValidationError';
import GrantPicker from './GrantPicker';

class SelectGrant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      existingGrantForCustomer: props.existingGrantForCustomer
    }
  }

  addGrant = () => {
    const currentTimestamp = new Date().getTime();
    const newGrantsForCustomer = _.merge(this.state.existingGrantForCustomer, { [currentTimestamp] : { grantId: '', isPi: false, errors: {} } });

    this.setState({
      existingGrantForCustomer: newGrantsForCustomer
    });
  }

  removeGrant = (grantId) => {
    const newGrantsForCustomer = _.omit(this.state.existingGrantForCustomer, [grantId]);

    this.setState({
      existingGrantForCustomer: newGrantsForCustomer
    });
  }

  render() {
    const existingGrantForCustomer = _.keys(this.state.existingGrantForCustomer).map((existingGrantIndex) => {
      const existingGrant = this.state.existingGrantForCustomer[existingGrantIndex];
      return(
        <GrantPicker key={existingGrantIndex} id={existingGrantIndex} grant_id={existingGrant.id} grants={this.props.grants} onclickHandler={this.removeGrant} {...existingGrant} />
      )
    });
    return (
      <fieldset>
        <legend>Associated grants</legend>
        <ValidationError wrapperClass='alert alert-danger' errors={this.props.errors} errorKey='customer_grants' />
        {existingGrantForCustomer}
        <AddGrantButton onclickHandler={this.addGrant}/>
      </fieldset>
    );
  }
}

export default SelectGrant;
