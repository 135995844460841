import React from 'react';
import Button from 'components/forms/Button';
import LayerForm from './LayerForm';

import LayerDetails from './LayerDetails';

const Layer = ({number, selectedLayer, layer, handleEditLayer, handleDeleteLayer, handleDuplicateLayer, editable=true, draggable=false, dragHandleProps, collections}) => {
  if (selectedLayer === layer.id) {
    return(
      <LayerForm collections={collections} />
    );
  } else {
    return (
      <div className='layer'>
        { draggable &&
          <div className='layer-draggable-area' {...dragHandleProps}>
            <i className='fas fa-grip-vertical fa-fw'></i>
          </div>
        }
        <div className='layer-content'>{number}. <LayerDetails {...layer} /></div>
        {
          editable && (
            <div className='btn-toolbar'>
              <Button className='btn-outline-secondary btn-sm' icon='clone' iconVariant='fad' onClickHandler={() => handleDuplicateLayer(layer) } text="Duplicate"/>
              <Button className='btn-outline-secondary btn-sm' icon='edit' iconVariant='far' onClickHandler={() => handleEditLayer(layer) } text="Edit"/>
              <Button className='btn-outline-danger btn-sm' icon='trash' iconVariant='far' onClickHandler={() => handleDeleteLayer(layer.id) } text="Delete" confirm="Are you sure you want to delete this layer?"/>
            </div>
          )
        }
      </div>
    );
  }
}

export default Layer;
