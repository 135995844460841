import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import deepmerge from 'deepmerge';

import { saveForm, cancelEdit } from 'actions/form.base.actions'

import Button from 'components/forms/Button';
import MachineSettingFields from './MachineSettingFields';


const MachineSettingsForm = ({machineSettings, handleSaveForm, handleCancelEdit}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSaveForm();
  }

  return(
    <form className="machine-settings-form" noValidate onSubmit={handleSubmit}>
      <MachineSettingFields machineSettings={machineSettings} />
      <div className='form-actions d-flex mt-4'>
        <Button
          icon='times'
          text='Cancel edit'
          className='btn-secondary'
          onClickHandler={handleCancelEdit} />

        <Button
          icon='save'
          text='Save settings'
          className='btn-primary ml-auto'
          type='submit' />
      </div>
    </form>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    machineSettings: state.form.machineSettings
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleSaveForm: saveForm,
    handleCancelEdit: cancelEdit
  }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(MachineSettingsForm)
