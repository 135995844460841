import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { Form, Characterisations } from 'reducers/CharacterisationPage';
import { characterisationFormValidationMiddleware, saveCharacterisationMiddleware, deleteCharacterisationMiddleWare } from 'middleware/characterisations';

import CharacterisationForm from './CharacterisationForm';
import CharacterisationList from './CharacterisationList';

class CharacterisationPage extends React.Component {
  render() {
    const { details, characterisations, collections } = this.props;

    const store = createStore(
      combineReducers({ form: Form, characterisations: Characterisations }),
      { characterisations: characterisations },
      applyMiddleware(characterisationFormValidationMiddleware, saveCharacterisationMiddleware, deleteCharacterisationMiddleWare));

    return (
      <Provider store={store}>
        <div className="characterisations">
          <CharacterisationList details={details} characterisations={characterisations} collections={collections} />
        </div>
      </Provider>
    );
  }
}

export default CharacterisationPage;
