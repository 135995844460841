import React from 'react';
import TextField from './TextField';
import FormField from './FormField';

export default function TextInput(props) {
  return (
    <FormField label={props.label} wrapperClassName={props.wrapperClassName} errors={props.errors}>
      <TextField {...props} />
    </FormField>
  );
}
