export const toggleProperty = (propertyType, propertyName) => ({
  type: 'TOGGLE_PROPERTY',
  propertyType,
  propertyName
})


export const setProperty = (propertyType, propertyName, value) => ({
  type: 'SET_PROPERTY',
  propertyType,
  propertyName,
  value
})


export const selectLayerToEdit = (layerId, machineSettings) => ({
  type: 'SELECT_LAYER_TO_EDIT',
  layerId,
  machineSettings
})


export const bulkEditSelectedLayers = () => ({
  type: 'BULK_EDIT_SELECTED_LAYERS'
})


export const updateMachineSettings = (layerIds, machineSettings) => ({
  type: 'UPDATE_MACHINE_SETTINGS',
  layerIds,
  machineSettings
})
