import { updateMachineSettings } from 'actions/GrowthRunManagementPage';
import { updateMachineSettingsForGrowth } from 'services/api/layer_requests';

export const saveMachineSettingMiddleware = ({ dispatch, getState }) => next => action => {
  const currentState = getState();

  if(action.type === 'SAVE_FORM') {
    let selectedLayers;

    if(currentState.form.bulkEditing) {
      selectedLayers = currentState.layers.filter((layer) => layer.selected).map(layer => layer.id);
    } else {
      selectedLayers = [currentState.form.selectedLayer];
    }
    
    let updatedMachineSettings = currentState.form.machineSettings;
    
    updateMachineSettingsForGrowth(currentState.growthRun.id, selectedLayers, updatedMachineSettings).then(res => {
      if(res.status === 200) {
        dispatch(updateMachineSettings(selectedLayers, updatedMachineSettings));
      }
    })
  }

  if(action.type === 'BULK_EDIT_SELECTED_LAYERS') {
    action.machineSettings = currentState.machineSettings['initial-settings'];

    Object.keys(action.machineSettings).forEach(machineProperty => {
      Object.keys(action.machineSettings[machineProperty]).forEach(key => {
        action.machineSettings[machineProperty][key]['value'] = '';
        action.machineSettings[machineProperty][key]['selected'] = false;
        action.machineSettings[machineProperty][key]['visible'] = false;
      });
    });
  }

  return next(action);
};
