import React from 'react';

import Button from 'components/forms/Button';
import PropertyField from './PropertyField';

export default function PropertyFields({ propertyType, propertyTypeName, properties=[], handlePropertyInput, handleNewProperty, handleRemoveProperty }) {

  return (
    <div className={`nested-fields ${propertyType}`}>
      <div className="property-type">
        {propertyTypeName}
        <Button icon="plus" text="Add" onClickHandler={() => handleNewProperty(propertyType)} className="btn btn-primary float-right" />
      </div>

      {properties.map((property, index) => <PropertyField key={index} name={property.name} defaultValue={property.defaultValue} handlePropertyInput={(value, key) => handlePropertyInput(value, propertyType, index, key)} handleRemoveProperty={() => handleRemoveProperty(propertyType, index)} /> )}
    </div>
  )
}
