import { moveDocument, moveGrowthRun } from "services/reports/reports.service"

const defaultState = {
  data: {
    growth_request: {}
  },
  selectedStructure: null,
  growth_runs: [],
  documents: {},
  form: {
    submitting: false,
    error: undefined
  }
}

export const Report = (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_STRUCTURE': {
      let newState = { ...state }
      newState.selectedStructure = action.structureId;
      newState.growth_runs = state.data.growth_structures.find(growth_structure => growth_structure.id === action.structureId).growth_runs
      newState.growth_runs.forEach(growth_run => growth_run.checked = false)
      newState.documents = {}

      return newState;
    }
    case 'TOGGLE_GROWTH_RUN': {
      let newState = { ...state }

      let selectedRunIndex = newState.growth_runs.findIndex(run => run.id === action.growthRunId)
      newState.growth_runs[selectedRunIndex].checked = !newState.growth_runs[selectedRunIndex].checked

      if(newState.growth_runs[selectedRunIndex].checked) {
        newState.documents[action.growthRunId] = {}
        newState.documents[action.growthRunId].draggable = newState.growth_runs[selectedRunIndex].documents.filter(document => ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'].includes(document.content_type))
        newState.documents[action.growthRunId].non_draggable = newState.growth_runs[selectedRunIndex].documents.filter(document => document.content_type === 'application/pdf')
        newState.documents[action.growthRunId].draggable.forEach(document => document.checked = true)
        newState.documents[action.growthRunId].non_draggable.forEach(document => document.checked = true)
      } else {
        delete newState.documents[action.growthRunId]
      }

      return newState;
    }
    case 'MOVE_GROWTH_RUN': {
      const { initialPosition, endPosition } = action;
      
      return moveGrowthRun(state, initialPosition, endPosition);
    }
    case 'MOVE_DOCUMENT': {
      const { runId, initialPosition, endPosition } = action;
      return moveDocument(state, runId, initialPosition, endPosition);
    }
    case 'TOGGLE_DOCUMENT': {
      let newState = { ...state };

      newState.documents[action.growthRunId][action.documentType] = [...newState.documents[action.growthRunId][action.documentType]]
      newState.documents[action.growthRunId][action.documentType][action.index].checked = !newState.documents[action.growthRunId][action.documentType][action.index].checked

      return newState;
    }
    case 'REPORT_REQUEST_SENT': {
      let newState = { ...state };
      newState.form.submitting = true;
      return newState;
    }
    case 'REPORT_REQUEST_SUCCESS': {
      let newState = { ...state };
      newState.form.submitting = false;
      return newState;
    }
    case 'REPORT_REQUEST_FAILURE': {
      let newState = { ...state };
      newState.form.submitting = false;
      newState.form.error = action.error;
      return newState;
    }
    default:
      return state;
  }
  return state;
}