import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { getReport } from 'middleware/report';

import SentryErrorBoundary from 'components/Sentry/SentryErrorBoundary'
import { Report } from 'reducers/CustomerReportBuilderPage';
import ReportForm from './ReportForm';

class CustomerReportBuilderPage extends React.Component {
  render() {
    const store = createStore(
      combineReducers({ report: Report }),
      { report: { data: this.props.growth_structure, growth_request: this.props.growth_request, selectedStructure: this.props.growth_structure, growth_runs: this.props.growth_structure.growth_runs, documents: [], form: { submitting: false, error: undefined } }},
      applyMiddleware(getReport)
    );

    return (
      <SentryErrorBoundary>
        <Provider store={store}>
          <ReportForm />
        </Provider>
      </SentryErrorBoundary>
    )
  }
}

export default CustomerReportBuilderPage;
