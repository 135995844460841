import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { machineFormValidationMiddleware, saveMachineMiddleware } from 'middleware/machines';

import { Form } from 'reducers/MachineManagementPage';

import SentryErrorBoundary from 'components/Sentry/SentryErrorBoundary';
import MachineForm from './MachineForm';

const MachineManagementPage = ({ collections, machine={ properties: {} } }) => {
  const store = createStore(combineReducers({ form: Form }), { form: buildFormFromMachine(machine) }, applyMiddleware(machineFormValidationMiddleware, saveMachineMiddleware));

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <MachineForm collections={collections} />
      </Provider>
    </SentryErrorBoundary>
  )
}

function buildFormFromMachine(machine) {
  return {
    values: {
      id: machine.id,
      name: machine.name || '',
      code: machine.code || '',
      growth_method: machine.growth_method || '',
      properties: {
        machineProperties: machine.properties.machineProperties || [],
        controls: machine.properties.controls || [],
        results: machine.properties.results || []
      }
    },
    errors: {}
  }
}

export default MachineManagementPage;
