import { getCsrfToken } from '../../scripts/csrf_helper';

export function getRequest({ url, params }) {
  return fetch(`${url}?${Object.keys(params).map(key => encodeURIComponent(key) + '=' + params[key]).join('&')}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    }
  })
}


export function postRequest({ url, body }) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    },
    body: JSON.stringify(body)
  })
}

export function patchRequest({ url, body }) {
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    },
    body: JSON.stringify(body)
  })
}


export function deleteRequest({ url }) {
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    }
  })
}
