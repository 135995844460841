import { saveError } from 'actions/form.base.actions';
import { characterisationValidations } from 'services/validations/customer/characterisations';

export const characterisationFormValidationMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SAVE_FORM") {
    return next(action)
  }

  const characterisation = action.form;

  let errors = { ...characterisationValidations(characterisation.values) }

  const anyErrors = Object.values(errors).some(errs => errs.length > 0);

  if(anyErrors) {
    dispatch(saveError(errors))
  } else {
    next(action)
  }
};
