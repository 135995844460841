import React from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormCheck } from 'react-bootstrap';
import { getNonce } from 'scripts/nonce_helper';

const RunsList = ({ handleGrowthRunSelect, runs, handleGrowthRunReorder }) => {
  return (
    <React.Fragment>
      <h3>Select run(s) to include</h3>
      <p>The runs may also be re-arranged into the order you want for the report.</p>
      <DragDropContext onDragEnd={handleGrowthRunReorder} nonce={getNonce()}>
        <Droppable droppableId='layer-list' >
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="runArea">
              { runs.map((run, index) =>
                <Draggable draggableId={`run_${run.id}`} index={index} key={run.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`draggableGrowthRun ${snapshot.isDragging ? 'dragging' : ''} ${run.checked === false ? 'unselected' : ''}`}>
                      <div className='draggableArea' {...provided.dragHandleProps}>
                        <i className='fas fa-grip-vertical fa-fw'></i>
                      </div>
                      <div className='draggableContent'>
                        <FormCheck
                          custom
                          type='checkbox'
                          key={`input_group_${run.id}`}
                          id={`run_radio_${run.id}`}
                          className="mr-4"
                          label={`${run.run_number || run.reference_number_string} - ${run.display_run_type}`}
                          checked={run.checked || false}
                          onChange={() => handleGrowthRunSelect(run.id)} />
                      </div>
                    </div>
                  )}
                </Draggable>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
}

const capitalize = (s) => {
  if(s) {
    return s 
  }
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default RunsList;