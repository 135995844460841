export function notANumber(value) {
  const match = value.match(/^\d+(\.\d+)?([eE][-]?\d+)?$/)
  return !Boolean(match)
}

export function empty(value) {
  return value === undefined || value === ''
}

export function present(value) {
  if(empty(value)) {
    return "can't be blank"
  }
}

export function runValidations(object, attributeValidators) {
  return Object.keys(attributeValidators).reduce((errors, field) => {
    errors[field] = attributeValidators[field].map(validator => validator(object[field])).filter(n => n)
    return errors
  }, {})
}
