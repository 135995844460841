import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import DocumentContent from './DocumentContent'

const DraggableDocument = ({ handleDocumentToggle, document, index }) => {
  return (
    <Draggable draggableId={`${document.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`draggableDocument document_${document.id} ${snapshot.isDragging ? 'dragging' : ''} ${document.checked === false ? 'unselected' : ''}`}>
          <div className='draggableArea' {...provided.dragHandleProps}>
            <i className='fas fa-grip-vertical fa-fw'></i>
          </div>
          <DocumentContent document={document} handleDocumentToggle={() => handleDocumentToggle(document.id)} />
        </div>
      )}
    </Draggable>
  )
}

export default DraggableDocument;