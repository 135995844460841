import { defaultFormValues, disableIncompatibleGroups, buildExistingLayerForForm, setDefaultPercentage } from 'services/layers';
import { baseFormReducer } from 'reducers/form.base.reducer';

const defaultState = {
  saving: false,
  type: undefined,
  selectedLayer: undefined,
  errors: {
    groups: {}
  }
}

export const Form = (state = defaultState, action) => {
  state = baseFormReducer(state, action);

  switch (action.type) {
    case 'TOGGLE_ELEMENT': {
      let newState = {...state};

      newState['groups'][action.group]['elements'][action.name]['selected'] = action.selected;
      setDefaultPercentage(newState['groups'][action.group]['elements']);
      newState['groups'] = disableIncompatibleGroups(newState['groups']);

      return newState;
    }
    case 'SET_PERCENTAGE':
      let newState = {...state};
      newState['groups'][action.group]['elements'][action.name]['percentage'] = action.percentage;
      return newState;
    case 'CANCEL_EDIT': {
      return {
        ...state,
        selectedLayer: undefined
      };
    }
    case 'SAVE_SUCCESS': {
      return {
        ...state,
        selectedLayer: undefined
      };
    }
    case 'SELECT_LAYER': {
      let layer = { ...action.layer };

      let newState = buildExistingLayerForForm(layer)

      newState.selectedLayer = layer.id;
      newState.type = 'editing'

      return newState;
    }
    case 'DUPLICATE_LAYER': {
      let layer = { ...action.layer };

      let newState = buildExistingLayerForForm(layer)
      newState.values.id = undefined;
      newState.selectedLayer = 0;
      newState.type = 'creating';

      return newState;
    }
    case 'NEW_LAYER': {
      let newState = defaultFormValues();
      newState.selectedLayer = 0;
      newState.type = 'creating';

      return newState;
    }
    default:
      return state;
  }
}
