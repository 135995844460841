import React from 'react';
import RemoveGrantButton from './RemoveGrantButton';
import ValidationError from './ValidationError';

class GrantPicker extends React.Component {
  removeGrantHandler = () => {
    this.props.onclickHandler(this.props.id);
  }

  formGroupClass = (key) => {
    if (_.isEmpty(this.props.errors) || _.isEmpty(this.props.errors[key])) {
      return '';
    } else {
      return 'form-group-invalid';
    }
  }

  formControlClass = (key) => {
    if (_.isEmpty(this.props.errors) || _.isEmpty(this.props.errors[key])) {
      return '';
    } else {
      return 'is-invalid';
    }
  }

  render() {
    const grantOptions = this.props.grants.map((grant) => {
      return (
        <option key={grant.id} value={grant.id}>{grant.name}</option>
      );
    });
    return (
      <div className='nested-fields' id={`grant_${this.props.id}`}>
        <div className={`form-label-group select ${this.formGroupClass('grant_id')}`}>
          <select className={`form-control ${this.formControlClass('grant_id')}`} required='required' name={`customer[customer_grant_attributes][${this.props.id}][grant_id]`} id={`customer_customer_grant_attributes_${this.props.id}_grant_id`} defaultValue={this.props.grantId}>
            <option value=""></option>
            {grantOptions}
          </select>
          <ValidationError errors={this.props.errors} errorKey='grant_id' />
          <label className="form-control-label" htmlFor={`customer_customer_grant_attributes_${this.props.id}_grant_id`}>Select a grant</label>
        </div>

        <div className='d-flex flex-row justify-content-between align-items-center'>
          <fieldset className="form-group boolean mb-0">
            <div className="custom-control custom-checkbox">
              <input name={`customer[customer_grant_attributes][${this.props.id}][pi]`} type="hidden" value="0" />
              <input className={`custom-control-input boolean ${this.formControlClass('pi')}`} required="required" aria-required="true" type="checkbox" defaultChecked={this.props.isPi} value="1" name={`customer[customer_grant_attributes][${this.props.id}][pi]`} id={`customer_customer_grant_attributes_${this.props.id}_pi`} />
              <label className="custom-control-label boolean" htmlFor={`customer_customer_grant_attributes_${this.props.id}_pi`}><abbr title="Principle Investigator">PI</abbr> of the grant?</label>
              <ValidationError errors={this.props.errors} errorKey='pi' />
            </div>
          </fieldset>
          <RemoveGrantButton id={`remove_grant_${this.props.grantId}`} onclickHandler={this.removeGrantHandler}/>
        </div>
      </div>
    );
  }
}

export default GrantPicker;
