import React from 'react';

const AddonAppend = ({ children, text }) => {
  return (
    <div className="input-group">
      { children }
      <div className="input-group-append">
        <span className="input-group-text" dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </div>
  )
}

export default AddonAppend;