export const startSearching = (params) => ({
  type: 'START_SEARCHING',
  params
});

export const finishSearching = (substrates) => ({
  type: 'FINISH_SEARCHING',
  substrates
});

export const searchFiltersRequired = () => ({
  type: 'SEARCH_FILTERS_REQUIRED'
});

