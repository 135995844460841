import { splitSubstrateRequest } from 'services/api/substrate_requests';
import { splitSuccess } from 'actions/SubstrateSelectionPage';
import { newAlert } from 'actions/Alerts';

export const splitSubstrateMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SPLIT_SUBSTRATE") {
    return next(action)
  }

  splitSubstrateRequest(action.substrateId, action.splitFactor).
    then(res => res.json()).
    then(res => {
      dispatch(newAlert('success', `Successfully split substrate into ${res.newSubstrates.map(substrate => substrate.serial_number).join(', ')}`))
      dispatch(splitSuccess(action.currentPosition, res.newSubstrates));
    }).
    catch(err => console.log(err));

  return next(action)
};
