export const updateFormValue = (name, value) => ({
  type: 'UPDATE_FORM_VALUE',
  name,
  value
});

export const saveError = (errors) => ({
  type: 'CHARACTERISATION_SAVE_FAILURE',
  errors
});

export const newCharacterisation = () => ({
  type: 'NEW_CHARACTERISATION'
})

export const editCharacterisation = (characterisation) => ({
  type: 'SELECT_CHARACTERISATION',
  characterisation
});

export const deleteCharacterisation = (characterisationId) => ({
  type: 'DELETE_CHARACTERISATION',
  characterisationId
});

export const deleteSuccess = (characterisationId) => ({
  type: 'CHARACTERISATION_DELETE_SUCCESS',
  characterisationId
})

export const deleteError = (errors) => ({
  type: 'CHARACTERISATION_DELETE_FAILURE',
  characterisation
})
