export const selectStructure = (structureId) => ({
  type: 'SELECT_STRUCTURE',
  structureId
});

export const toggleGrowthRun = (growthRunId) => ({
  type: 'TOGGLE_GROWTH_RUN',
  growthRunId
});

export const moveGrowthRun = (initialPosition, endPosition) => ({
  type: 'MOVE_GROWTH_RUN',
  initialPosition,
  endPosition
})

export const toggleDocument = (growthRunId, index, documentType) => ({
  type: 'TOGGLE_DOCUMENT',
  growthRunId,
  index,
  documentType
});

export const moveDocument = (runId, initialPosition, endPosition) => ({
  type: 'MOVE_DOCUMENT',
  runId,
  initialPosition,
  endPosition
})

export const submitReport = () => ({
  type: 'SUBMIT_REPORT'
})

export const reportRequestSent = () => ({
  type: 'REPORT_REQUEST_SENT'
})

export const reportRequestSuccess = () => ({
  type: 'REPORT_REQUEST_SUCCESS'
})

export const reportRequestFailure = (error) => ({
  type: 'REPORT_REQUEST_FAILURE',
  error
})