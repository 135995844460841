import { saveError, saveSuccess } from 'actions/form.base.actions';
import { createCharacterisation, updateCharacterisation } from 'services/api/characterisation_requests';
import { getSelectedElementsFromGroups } from 'services/characterisations';

export const saveCharacterisationMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "SAVE_FORM") {
    return next(action)
  }

  const characterisation = action.form;
  const { id, ...payload } = characterisation.values;

  let request = undefined;

  if(characterisation.type === 'editing') {
    request = updateCharacterisation(id, payload)
  } else if(characterisation.type === 'creating') {
    request = createCharacterisation(action.details.growth_request_id, action.details.growth_structure_id, payload)
  }

  request
    .then(res => res.json())
    .then(res => dispatch(saveSuccess(res)))
    .catch(err => dispatch(saveError(err)));
};
