import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { layerFormValidationMiddleware, saveLayerMiddleware, deleteLayerMiddleWare, moveLayerMiddleWare } from 'middleware/layers';

import { Form, Layers } from 'reducers/LayersManagementPage';

import LayerList from './LayerList';
import SentryErrorBoundary from 'components/Sentry/SentryErrorBoundary';

class LayersManagementPage extends React.Component {
  render() {
    const store = createStore(combineReducers({ form: Form, layers: Layers }), { layers: this.props.layers }, applyMiddleware(layerFormValidationMiddleware, saveLayerMiddleware, deleteLayerMiddleWare, moveLayerMiddleWare));

    return (
      <SentryErrorBoundary>
        <Provider store={store}>
          <LayerList details={ this.props.details } collections={ this.props.collections }/>
        </Provider>
      </SentryErrorBoundary>
    );
  }
}

export default LayersManagementPage;
