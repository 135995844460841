import React from 'react';
import Substrate from './Substrate';

const SelectedSubstrates = ({ substrates, handleSubstrateToggle }) => {
  return (
    <div className="selectedSubstrates mb-4">
      { substrates.map((substrate, index) => (
        <Substrate key={`selected_substrate${substrate.id}`} selected={true} substrate={substrate} index={index} handleSelectSubstrate={handleSubstrateToggle} />
      ))}
    </div>
  )
}

export default SelectedSubstrates;