import { bindSubstrateRequest } from 'services/api/substrate_requests';
export const bindSubstrateMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== "BIND_SUBSTRATE") {
    return next(action)
  }

  const selectedSubstrates = Object.keys(getState().selectedSubstrates);

  bindSubstrateRequest(action.growthRunId, selectedSubstrates).
    then(res => {
      window.location.reload();
    }).
    catch(err => console.log(err))

  return next(action)
};
