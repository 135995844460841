import { moveLayer } from 'services/api/layer_requests';

import { layerMoveFailure } from 'actions/LayersManagement'

export const moveLayerMiddleWare = ({ dispatch, getState }) => next => action => {
  if(action.type !== "MOVE_LAYER") {
    return next(action)
  }

  const { initialPosition, endPosition } = action;
  const { layers } = getState();
  const { id } = layers[initialPosition];

  moveLayer(id, endPosition)
    .then(res => {
      if(res.status === 422 || res.status === 500) {
        dispatch(layerMoveFailure(initialPosition, endPosition));
      }
    })
    .catch(err => console.log(err))

    next(action)
};
