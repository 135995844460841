import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { newCharacterisation, editCharacterisation, deleteCharacterisation } from 'actions/CharacterisationsPage';

import CharacterisationForm from './CharacterisationForm';
import Characterisation from './Characterisation';
import Button from 'components/forms/Button';

const CharacterisationList = ({ characterisations, form, details, ...props }) => {
  let newCharacterisationForm;
  let characterisationsList;

  if (details.editable) {
    if (form.type === 'creating') {
      newCharacterisationForm = (<CharacterisationForm details={details} collections={props.collections} />)
    } else {
      newCharacterisationForm = (
        <Button
          text='Add a new characterisation'
          icon='plus'
          className='btn-primary'
          onClickHandler={props.newCharacterisation} />
      )
    }
  }

  if (characterisations.length > 0) {
    characterisationsList = (
      <div className='characterisation-list'>
        { characterisations.map(characterisation => (
          <Characterisation
            key={characterisation.id}
            collections={props.collections}
            selectedCharacterisation={form.selectedCharacterisation}
            characterisation={characterisation}
            editable={details.editable}
            handleEditCharacterisation={props.editCharacterisation}
            handleDeleteCharacterisation={props.deleteCharacterisation}
          />
        ))
      }
      </div>
    )
  } else if (!details.editable) {
    characterisationsList = (<span className='text-muted'>No characterisations for this structure.</span>)
  }



  return (
    <React.Fragment>
      { newCharacterisationForm }
      {  characterisationsList  }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
    characterisations: state.characterisations
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    newCharacterisation: newCharacterisation,
    editCharacterisation: editCharacterisation,
    deleteCharacterisation: deleteCharacterisation
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CharacterisationList)
